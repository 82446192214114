import React, { useState} from 'react';
import Box from '@mui/material/Box';
import {Stepper, Step, StepButton, TextField, Button, Divider, Grid, Select, MenuItem } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import Step1 from './Step1';
import Step2 from './Step2'; // Import Step2 component
import Step3 from './Step3'; // Import Step3 component
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { createStudent, uploadDocImages } from 'src/redux/actions/student.action';
import { notifyErrorFxn } from 'src/utils/toast-fxn';
import { saveThemeColor, saveThemeImage,saveThemeColorUnsaved, saveThemeImageUnsaved,  saveThemeImageBlob,saveSubscriptionCost,saveNumberOfStudents } from 'src/redux/reducers/settings.slice';
import BONLOGO from 'src/assets/images/logo.png'
import orangeImg from 'src/assets/images/orange-button-img.png'
import axios from 'axios';
import * as uuid from 'uuid';
import { savePayTokenToDatabase } from 'src/redux/actions/settings.action';


const steps = ['Informations de Base', 'Information Additionnelle', 'Téléchargements de Docs'];


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '4rem',
    paddingRight: '4rem',
  },
  searchInput: {
    background: 'white',
    border: '1px solid #00000026',
    padding: '0px',
    borderRadius: '8px',
    // marginRight: theme.spacing(2),
    width: '100%',
    minWidth: '100%',
    '& .MuiInputBase-input': {
      color: 'grey',
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'grey',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'grey',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'grey',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey',
    },
  },
  searchButton: {
    color: '#fff',
    padding: '15px',
    minWidth: '45%',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export default function SettingsSubscriptionBox() {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const [state, setState] = useState({
    fname:  "",
    lname: "",
    dob: "",
    gender: "",
    studentshipType: "",
    registrationId: "",
    class: "",
    section: "",
    guardianName: "",
    bloodGroup: "",
    religion: "",
    phoneNumber: "",
    email: "",
    skinColor: "",
    eyeColor: "",
    height: "",
   nationality: "",
    admissionDate: "",
    admissionTerminated: "",
    medicalHistory: "",
    specialInstruction: ""
  })

  const {themeColorUnsaved,themeImageUnsaved,subscriptionCost,numberOfStudents} = useSelector((state)=>state.settings)

  const {user,school} = useSelector((state)=>state.auth)

  const [subscriptionCostLocal,setSubscriptionCostLocal] = useState(subscriptionCost)

  const [numberOfStudentsLocal,setNumberOfStudentsLocal] = useState(numberOfStudents)
  

  const [selectedFile4, setSelectedFile4] = useState({selectedFile: [], selectedFileName: []});
const [file4, setFile4] = useState();
const handleselectedFile4 = event => {
  setSelectedFile4({
      selectedFile: event.target.files[0],
      selectedFileName: event.target.files[0].name
  });
  setFile4(URL.createObjectURL(event.target.files[0]));

dispatch(saveThemeImageUnsaved(URL.createObjectURL(event.target.files[0])))
dispatch(saveThemeImageBlob(event.target.files[0]))
};

  const [studentPassportFile, setStudentPassportFile] = useState({selectedFile: [], selectedFileName: []});
  const [anotherFieldFile, setAnotherFieldFile] = useState({selectedFile: [], selectedFileName: []});
  const [mothersIdFile, setMothersIdFile] = useState({selectedFile: [], selectedFileName: []});
  const [certificateFile, setCertificateFile] = useState({selectedFile: [], selectedFileName: []});
  const [medicalRecordFile, setMedicalFile] = useState({selectedFile: [], selectedFileName: []});

  const [surveyAnswer,setSurveyAnswer] = useState('')


  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });
  }

  const handleStudentPassportFile = event => {
    setStudentPassportFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
};
  const handleAnotherFieldFile = event => {
    setAnotherFieldFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
};
  const handleMothersIdFile = event => {
    setMothersIdFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
};
  const handleCertificateFile = event => {
    setCertificateFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
};
  const handleMedicalFile = event => {
    setMedicalFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
};


//const orangeMTokenUrl = 'http://localhost:5008/api/om/get-token';
  //const orangeMPaymentUrl = 'http://localhost:5008/api/om/webpayment';
  const orangeMTokenUrl = 'https://vercel-code-14me.vercel.app/api/om/get-token';
  const orangeMPaymentUrl = 'https://vercel-code-14me.vercel.app/api/om/webpayment-schools';
  const [isLoading,setIsLoading] = useState(false)


const handleOrangePay = async () => {

   console.log('subscription cost -->',Number(subscriptionCost))

  if(!Number(subscriptionCost)){
   notifyErrorFxn("please make sure you've selected a viable number of students!")
   return
  }
 
   const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',  
   };
     
     axios.post(orangeMTokenUrl, {}, { headers })
      .then(response => {
          const access_token = response.data.access_token;
          const generateOrderId = uuid.v4()
          setIsLoading(true);
        
         axios.post(orangeMPaymentUrl, {
          amount: Number(subscriptionCost),
          currency: 'GNF', //OUV
          order_id: `${generateOrderId}`,
          userId:user.id,
          /*cartData:cartToSubmit,*/
          reference: "ref Merchant",
          orangeMToken: access_token
        }).then((res) => {
            //console.log("RESPONSE IS--->", res.data);
            if (res.data.payment_url) {
              dispatch(savePayTokenToDatabase(user.id,res.data.pay_token,Number(subscriptionCost),generateOrderId)).then(()=>{
             
              window.open(res.data.payment_url, '_blank');
                }
              )
            }else{
              //console.log("Res", res);
              notifyErrorFxn("An error occured,please try again !");  
            }
        }).catch((error) => {
          setIsLoading(false);
          console.error('Payment Request Error:', error);
          notifyErrorFxn('Payment Request Error...');
        })
      }).catch(error => {
          setIsLoading(false);
          notifyErrorFxn('Failed to get token');
      });
};


  // Helper function to render the appropriate step content based on the activeStep
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Step1 state={state} handleChange={handleChange} />;
      case 1:
        return <Step2 state={state} handleChange={handleChange} />;
      case 2:
        return <Step3 
        studentPassportFile={studentPassportFile}
        handleStudentPassportFile={handleStudentPassportFile}
        anotherFieldFile={anotherFieldFile}
        handleAnotherFieldFile={handleAnotherFieldFile}
        mothersIdFile={mothersIdFile}
        handleMothersIdFile={handleMothersIdFile}
        certificateFile={certificateFile}
        handleCertificateFile={handleCertificateFile}
        medicalRecordFile={medicalRecordFile}
        handleMedicalFile={handleMedicalFile}
        />;
      default:
        return 'Unknown step';
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const files = [
      studentPassportFile.selectedFile,
      anotherFieldFile.selectedFile,
      mothersIdFile.selectedFile,
      certificateFile.selectedFile,
      medicalRecordFile.selectedFile,
    ].filter(file => file); 
    
    try {
      setLoading(true);
     const urls = await Promise.all(files.map((file) => {
        if (file) {
          return dispatch(uploadDocImages(file));
        }
        return null;
      }));

  
      const [studentPassportFileUrl, anotherFieldFileUrl, mothersIdFileUrl, certificateFileUrl, medicalRecordFileUrl] = urls;
  
      const studentData = { state,  studentPassportFileUrl, anotherFieldFileUrl, mothersIdFileUrl, certificateFileUrl, medicalRecordFileUrl};
      dispatch(createStudent(studentData, navigate, setLoading));
    } catch (error) {
      // setLoading(false);
      console.error("Error uploading images: ", error);
      // notifyErrorFxn("Error occured uploading Images");
    }
  };
  

  return (
    <Box sx={{ width: '100%' }}>
    {/*  <div style={{ display: 'flex', width: '100%' }}>
  <div style={{ flex: 1 }}>
    <Stepper nonLinear activeStep={activeStep} style={{ border: '0px solid red', background: 'white' }}>
      {steps.map((label, index) => (
        <Step key={label} completed={completed[index]}>
          <StepButton color="inherit" onClick={handleStep(index)}>
            {label}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  </div>
</div> */}

      <div>
        {allStepsCompleted() ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </>
        ) : (
          <>
            <Typography sx={{ mt: 2, mb: 1, py: 1, ml: 5, color: '#000000', fontSize: '18px' }}>
              <b>{activeStep === 0 && 'Abonnement'}</b>
            </Typography>
           {/*<Divider />*/}
            <br />
            {/* Render the appropriate step content */}
            <Grid container style={{display:"flex",justifyContent:"center",gap:"2rem"}}  spacing={4}>
        <Grid item xs={5}>
          <Typography variant="subtitle1" style={{marginBottom:"1rem"}}>Nombre d'étudiants</Typography>
          {/*<TextField
            name="fname"
            placeholder="Color"
            fullWidth
            value={state.fname}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
                
              style:{
               
                width:"100%",
                height:"3rem",
                backgroundColor:"white",
                border:"0px solid lightgrey",
                padding:"10px"
              }
            }}
          />*/}


        


   {<Select
          style={{backgroundColor:"#FFFFFF",borderRadius:"0.4rem",width:"100%",padding:"10px",height:"3rem", border:"1px solid lightgrey",}}
         inputProps={{
          classes: {
              icon: classes.icon,
          },

          style:{
               
            width:"100%",
            height:"3rem",
            backgroundColor:"white",
            border:"0px solid lightgrey",
            padding:"10px"
          }
      }}
        
          labelId="hi-label"
          id="hi"
          value={numberOfStudentsLocal}
          displayEmpty
          renderValue={(selected) => {
            if (selected && selected.length === 0) {
              return <em style={{color:"lightgray"}}>Sélectionner un Numéro</em>;
            }

            return selected;
          }}
          onChange={(event) => {
            setNumberOfStudentsLocal(event.target.value);
            dispatch(saveNumberOfStudents(event.target.value));

            if(event.target.value ==='1 - 500'){
              dispatch(saveSubscriptionCost('75000000'));
              setSubscriptionCostLocal('7,5000,000')
            }
            else if(event.target.value ==='501 - 1000'){
           
              dispatch(saveSubscriptionCost('11250000'));
              setSubscriptionCostLocal('11,250,000')
            }
            else if(event.target.value ==='1001 - 2000'){
          
              dispatch(saveSubscriptionCost('22500000'));
              setSubscriptionCostLocal('22,500,000')
            }
           else if(event.target.value ==='2001+'){
            dispatch(saveSubscriptionCost('37500000'));
              setSubscriptionCostLocal('37,500,000')
           }
          else {
            dispatch(saveSubscriptionCost('0'));
            setSubscriptionCostLocal('0')
          }

          }}
        >
       
    <MenuItem disabled value="">sélectionner un numéro</MenuItem> 
  <MenuItem  value={"1 - 500"}>1 - 500</MenuItem>

  <MenuItem   value={"501 - 1000"}>501 - 1000 </MenuItem>
  <MenuItem   value={"1001 - 2000"}>1001 - 2000 </MenuItem>
  <MenuItem   value={"2001+"}>2001+ </MenuItem>
  
 


       
        </Select>
        }




        </Grid>
        <Grid item xs={5} style={{position:"relative"}}>
          <Typography variant="subtitle1" style={{marginBottom:"1rem"}}>Coût (FG) </Typography>
          <TextField
            name="cost"
            placeholder="coût de l'abonnement"
            component="label"
            fullWidth
            value={subscriptionCostLocal}
           // onChange={(event) => {
           //   setSubscriptionCostLocal(event.target.value);
           //   dispatch(saveSubscriptionCost(event.target.value));
           // }}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,

                
              style:{
               
                width:"100%",
                height:"3rem",
                backgroundColor:"white",
                border:"0px solid lightgrey",
                padding:"10px"
              }
            }}
          />
        
           {/*
          <div style={{backgroundColor:"white",height:"2.5rem",width:"6.3rem",position:"absolute",top:"5rem",left:"2.2rem",zIndex:"2"}}></div>
           
                <input
                  type="file"
                  style={{ display: 'flex',width:"100%",position:"relative",top:"-2rem",left:"0.2rem",opacity:!selectedFile4.selectedFileName?"0":"1",fontFamilt:"Arial" }}
                  onChange={handleselectedFile4}
                   />
          */}
        

         </Grid>
        </Grid>



            <br/><br />
            <center>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 ,marginLeft:"-4rem"}}>
               <Grid container direction="row" justifyContent="center" alignItems="center">
               <Button
                variant="contained"
               
                onClick={handleOrangePay}
                style={{
                  cursor:"pointer",
                  display:'inline-block',
                  minWidth: '125px',
                  backgroundColor: '#D72A34'/*'#f07401'*/,
                  /*border: '1px solid #000000',*/
                  color: '#FFFFFF',
                  marginLeft: '4rem',
                  paddingTop: '15px',
                  paddingBottom: '15px',
                  paddingLeft: '20px',
                }}
              >
               {isLoading?'Chargement...':'Payer Abonnement'}
              </Button>

              

              </Grid>
              <Box sx={{ flex: '1 1 auto' }} />
            </Box>
          </center>

          <br />

          </>
        )}
      </div>
    </Box>
  );
}
