import { createSlice } from '@reduxjs/toolkit';

const initialState = {
      themeColor:null,
      themeColorUnsaved:null,
      themeImage:"",
      themeImageUnsaved:null,
      themeImageBlob:"",
      holidayStart:"",
      reloadSwitch:false,
      messageLoaded:false,
      orangeMerchantApi:" ",
      numberOfStudents:" ",
      subscriptionCost:" ",
      onlinePayment:" ",
      holidayEnd:"",
      tuitionFeeAtLevel:"",
      selectedLevelToAddToSchool:"",
      subjectsForLevel:[],
      termSecondary:"",
      termElementary:"",
      examsPerTerm:1,
      installment:"",
      passwordTeacher:"",
      apeae:"",
      tuitionFee:"",
      admissionFee:" ",
      readmissionFee:" ",
      resumptionDate:"",

      cartToProcess:{},
      mostRecentPayToken:'',
      mostRecentOrderAmount:'',
      mostRecentOrderId:'',
      
      isLoading: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
   saveThemeColor: (state, action) => {
        state.themeColor= action.payload;
    },
    saveThemeColorUnsaved: (state, action) => {
      state.themeColorUnsaved= action.payload;
  },

  savePasswordTeacher: (state, action) => {
    state.passwordTeacher= action.payload;
},

    saveThemeImage: (state, action) => {
      state.themeImage= action.payload;
  },

  saveReloadSwitch: (state, action) => {
    state.reloadSwitch= action.payload;
},
saveMessageLoaded: (state, action) => {
  state.messageLoaded= action.payload;
},

  saveThemeImageUnsaved: (state, action) => {
    state.themeImageUnsaved= action.payload;
},
saveNumberOfStudents: (state, action) => {
  state.numberOfStudents= action.payload;
},
saveSubscriptionCost: (state, action) => {
  state.subscriptionCost= action.payload;
},

saveSelectedLevelToAddToSchool: (state, action) => {
  state.selectedLevelToAddToSchool= action.payload;
},
saveTuitionFeeAtLevel: (state, action) => {
  state.tuitionFeeAtLevel= action.payload;
},

saveTermElementary: (state, action) => {
  state.termElementary= action.payload;
},

saveExamsPerTerm: (state, action) => {
  state.examsPerTerm= action.payload;
},


saveTermSecondary: (state, action) => {
  state.termSecondary= action.payload;
},

saveSubjectsForLevel: (state, action) => {
  state.subjectsForLevel= action.payload;
},

  saveThemeImageBlob: (state, action) => {
    state.themeImageBlob= action.payload;
},

saveHolidayStart: (state, action) => {
  state.holidayStart= action.payload;
},

saveHolidayEnd: (state, action) => {
  state.holidayEnd= action.payload;
},
saveResumptionDate: (state, action) => {
  state.resumptionDate= action.payload;
},

saveAPEAE: (state, action) => {
  state.apeae= action.payload;
},
saveTuitionFee: (state, action) => {
  state.tuitionFee= action.payload;
},
saveAdmissionFee: (state, action) => {
  state.admissionFee= action.payload;
},
saveReadmissionFee: (state, action) => {
  state.readmissionFee= action.payload;
},
saveOrangeMerchantApi: (state, action) => {
  state.orangeMerchantApi= action.payload;
},
saveOnlinePayment: (state, action) => {
  state.onlinePayment= action.payload;
},
saveInstallment: (state, action) => {
  state.installment= action.payload;
},

saveCartToProcess: (state, action) => {
  state.cartToProcess = action.payload;
  
},

saveMostRecentPayToken: (state, action) => {
  state.mostRecentPayToken = action.payload;
  
},

saveMostRecentOrderAmount: (state, action) => {
  state.mostRecentOrderAmount = action.payload;
  
},

saveMostRecentOrderId: (state, action) => {
  state.mostRecentOrderId = action.payload;
  
},


    isItLoading: (state, action) => {
      state.isLoading = action.payload;
  },
    clearGroup: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

const { actions, reducer } = settingsSlice;

export const {
 saveThemeColor,
 saveThemeImage,
 saveThemeColorUnsaved,
 savePasswordTeacher,
 saveThemeImageUnsaved,
 saveReloadSwitch,
 saveNumberOfStudents,
 saveSubscriptionCost,
 saveMessageLoaded,
 saveThemeImageBlob,
 saveHolidayEnd,
 saveInstallment,
 saveAPEAE,
 saveTuitionFee,
 saveAdmissionFee,
 saveReadmissionFee,
 saveOrangeMerchantApi,
 saveOnlinePayment,
 saveHolidayStart,
 saveResumptionDate,
 saveTuitionFeeAtLevel,
saveSelectedLevelToAddToSchool,
saveSubjectsForLevel,
saveTermSecondary,
saveTermElementary,
saveExamsPerTerm,

saveCartToProcess,
 saveMostRecentOrderAmount,
 saveMostRecentOrderId,
 saveMostRecentPayToken,
 clearGroup

} = actions;

export default reducer;


