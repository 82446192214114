import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography, FormControl, Box, Select, MenuItem, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyGroups } from 'src/redux/actions/group.action';
import { fetchUserData, updateUserSettings, uploadProfileSettings } from 'src/redux/actions/auth.action';

import merge from 'lodash/merge';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { fetchMyTransactions } from 'src/redux/actions/transaction.action';
import CustomToggleSwitch from 'src/components/buttons/CustomToogleSwitch';
import CustomSearchBar from 'src/components/global/CustomSearchBar';
import SearchIcon from '@mui/icons-material/Search';
import ViewStudents from 'src/components/students/ViewStudents';
import AddStudent from 'src/components/students/AddStudent';
import { getStudents } from 'src/redux/actions/student.action';
import SettingsTopBox from 'src/components/students/SettingsTopBox';
import SettingsBottomBox from 'src/components/students/SettingsBottomBox';
import { saveThemeColor, saveThemeImage } from 'src/redux/reducers/settings.slice';
import SettingsDateBox from 'src/components/students/SettingsDateBox';
import SettingsFeesBox from 'src/components/students/SettingsFeesBox';
import SettingsSubscriptionBox from 'src/components/students/SettingsSubscriptionBox';
import SettingsLevelsBox from 'src/components/students/SettingsLevelsBox';



export default function SettingsPage() {
  const theme = useTheme();
    
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user,school } = useSelector((state) => state.auth);

  

  const { 
    themeColor,
    themeColorUnsaved,
    themeImageUnsaved,
    themeImageBlob,
     holidayEnd,
     holidayStart,
     resumptionDate,
     installment,
     apeae,
     tuitionFee,
     admissionFee,
     readmissionFee,
     onlinePayment,
     passwordTeacher,
     orangeMerchantApi,
     examsPerTerm,
     numberOfStudents,
     subscriptionCost,
     tuitionFeeAtLevel,
     selectedLevelToAddToSchool,
     subjectsForLevel,
     termSecondary,
     termElementary
    
  } = useSelector((state) => state.settings);


  const [finalThemeColor,setFinalThemeColor] = useState(themeColorUnsaved)
  const [finalThemeImage,setFinalThemeImage] = useState(themeImageUnsaved)


  useEffect(()=>{

    if(!themeColor){
    dispatch(saveThemeColor( school && school.settings &&  school.settings.themeColor))
    dispatch(saveThemeImage(school && school.settings &&  school.settings.themeImage))
    }
   
   
     },[])


     useEffect(()=>{
   
      setFinalThemeColor(themeColorUnsaved)
      setFinalThemeImage(themeImageUnsaved)

      console.log("final theme color-->",themeColorUnsaved)
     },[themeColorUnsaved,themeImageUnsaved])


  const { students } = useSelector((state) => state.student);

  const preferredSettings = {
    themeColorUnsaved:finalThemeColor,
    themeImageUnsaved:finalThemeImage
  }


  useEffect(() => {
    dispatch(getStudents());
    dispatch(fetchUserData(user?.id));
  }, [])



  const [selectedOption, setSelectedOption] = useState('');
  const [activeButton, setActiveButton] = useState('viewStudents');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleViewStudentsClick = () => {
    setActiveButton('viewStudents');
 
  };

  const handleAddStudentsClick = () => {
    setActiveButton('addStudents');
  };

  return (
    <>

      <Container maxWidth="xl">
       {/* <Grid container spacing={2} alignItems="center">
     <CustomToggleSwitch activeButton={activeButton} setActiveButton={setActiveButton} handleViewStudentsClick={handleViewStudentsClick} handleAddStudentsClick={handleAddStudentsClick}/>
     <Grid item sx={{mb: 2}}>
     <FormControl sx={{ minWidth: 140 }}>
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            displayEmpty
            label=""
            sx={{
            
              minWidth: 140,
              p: 1,
            }}
          >
            <MenuItem value="">
              Select Class
            </MenuItem>
             <MenuItem value={'Level 1'}>Niveau 1</MenuItem>
        <MenuItem value={'Level 2'}>Niveau 2</MenuItem>
        <MenuItem value={'Level 3'}>Niveau 3</MenuItem>
        <MenuItem value={'Level 4'}>Niveau 4</MenuItem>
        <MenuItem value={'Level 5'}>Niveau 5</MenuItem>
        <MenuItem value={'Level 7'}>Niveau 7</MenuItem>
        <MenuItem value={'Level 8'}>Niveau 8</MenuItem>
        <MenuItem value={'Level 9'}>Niveau 9</MenuItem>
        <MenuItem value={'Level 10'}>Niveau 10</MenuItem>
        <MenuItem value={'Level 11'}>Niveau 11</MenuItem>
        <MenuItem value={'Level 12'}>Niveau 12</MenuItem>
        <MenuItem value={'Level 13'}>Niveau 13</MenuItem>
          </Select>
        </FormControl>
      </Grid>
     <Grid item sx={{mb: 2}}>
     <FormControl sx={{ minWidth: 140 }}>
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            displayEmpty
            label=""
            sx={{
           
              minWidth: 140,
              p: 1,
            }}
          >
            <MenuItem value="">
              Select Section
            </MenuItem>
            <MenuItem value={1}>Option 1</MenuItem>
            <MenuItem value={2}>Option 2</MenuItem>
            <MenuItem value={3}>Option 3</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      &nbsp; &nbsp;
      <Box sx={{ width: '20%' }}>
        <CustomSearchBar  title={"Recherche étudiant"} />
      </Box>
      <Box sx={{ flexGrow: 1}}>
        <Button
          variant="contained"
          style={{ minHeight: '50px', minWidth: '45px', backgroundColor: '#000000' }}
        >
          <SearchIcon />
        </Button>
      </Box>

      <Grid item sx={{mb: 2}}>
     <FormControl sx={{ minWidth: 140 }}>
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            displayEmpty
            label=""
            sx={{
            //   minHeight: 30,
              minWidth: 120,
              p: 1,
            }}
          >
            <MenuItem value="">
              Filtrer par
            </MenuItem>
            <MenuItem value={1}>Option 1</MenuItem>
            <MenuItem value={2}>Option 2</MenuItem>
            <MenuItem value={3}>Option 3</MenuItem>
          </Select>
        </FormControl>
      </Grid>

          </Grid> */}
          <br/>
          
        <Grid container spacing={2}>
            <Grid item xs={8} md={12} lg={12}>
             
            <div style={{background: '#F8F8F8',  padding: '10px',marginTop:"2rem"}}>
               { <SettingsLevelsBox />}
                </div>

            <div style={{background: '#F8F8F8',  padding: '10px',marginTop:"2rem"}}>
               { <SettingsFeesBox />}
                </div>
                

                <br/>

            <div style={{background: '#F8F8F8',  padding: '10px',marginTop:"2rem"}}>
               { <SettingsDateBox />}
                </div>

                <br/>
               

                <div style={{background: '#F8F8F8',  padding: '10px'}}>
               { <SettingsBottomBox />}
                </div>

              
                <br/>
               
                <div style={{background: '#F8F8F8',  padding: '10px'}}>
               { <SettingsTopBox />}
                </div>
                
                


             

          <center style={{marginTop:"1rem"}}>
            <Button disabled={false} variant="contained" onClick={()=>{
              if(themeImageBlob){
                dispatch(uploadProfileSettings( 
                  {themeColorUnsaved:themeColorUnsaved,
                    themeImageUnsaved:themeImageUnsaved,
                    holidayStart,
                    holidayEnd,
                    resumptionDate,
                    installment,
                    apeae,
                    tuitionFee,
                    admissionFee,
                    readmissionFee,
                    onlinePayment,
                    orangeMerchantApi,
                    examsPerTerm,
                    passwordTeacher,
                     tuitionFeeAtLevel,
                  selectedLevelToAddToSchool,
                  subjectsForLevel,
                  termSecondary,
                  termElementary},themeImageBlob,user && user.schoolId? user.schoolId:user && user.id ))
              }else{
              dispatch(updateUserSettings(
                {themeColorUnsaved:themeColorUnsaved,
                themeImageUnsaved:themeImageUnsaved,
                holidayStart,
                holidayEnd,
                resumptionDate,
                installment,
                apeae,
                tuitionFee,
                admissionFee,
                readmissionFee,
                onlinePayment,
                orangeMerchantApi,
                examsPerTerm,
                passwordTeacher,
                 tuitionFeeAtLevel,
                selectedLevelToAddToSchool,
                subjectsForLevel,
                termSecondary,
                termElementary},user && user.schoolId? user.schoolId:user && user.id ))
              }
            }}
              style={{ minWidth: '125px', backgroundColor: themeColor?themeColor:"#D72A34", marginLeft:  '1rem', paddingTop: '15px', paddingBottom: '15px', paddingLeft: '20px' }}>
              Soumettre
            </Button>
          </center>



          <br/>
               
               <div style={{background: '#F8F8F8',  padding: '10px'}}>
              { <SettingsSubscriptionBox />}
               </div>

            </Grid>
            
          </Grid>
      </Container>
    </>
  );
}
