import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import format from 'date-fns/format';
import StatusIcon from './StatusIcon';
import { useEffect } from 'react';
import { setDemo, setRecruiter } from 'src/redux/reducers/auth.slice';
//import { unMatchConnect, updateConnection } from 'redux/actions/user.action';

const useStyles = makeStyles((theme) => ({
  contactListItem: {
    '&.active': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  unreadBadge: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },

}));




function AnnouncementListItem(props) {

  const classes = useStyles(props);
  // const selectedContactId = 'tDsFHoucxvMTreIJMBDnftrbrtG2';
  const dispatch = useDispatch();
  const history = useNavigate();
  //const { user } = useSelector((state) => state.login);
  const selectedContactId = props.user.uid;




  const accRejInvite = (users, status) => {

    const user1 = users.uid;
    //const user2 = user.uid;
  
   // console.log('Accepted User: ', user);
    //dispatch(updateConnection(user1, user2, status, history));
  }
  
 // const unMatch = (users) => {
 //    const user1 = users.uid;
 //    const user2 = user.uid;
//
 //    console.log('User 1:- ', user1);
 //    console.log('User 2:- ', user2);
 //    //dispatch(unMatchConnect(user1, user2, history));
 // }

  return (
    <ListItem
    button
   
    className={clsx(classes.contactListItem, 'px-8 py-2 min-h-56', {
      // active: props.selectedContactId === props.contact.id,
      active: selectedContactId === props.user.id,
    })}
  onClick={() =>{ props.onContactClick({...props.user,newAnnouncement:false}) }}
  >
   {/* <div className="relative">
      <div className="absolute right-0 bottom-0 -m-4 z-10">
      
      </div>

      <Avatar src={props.user.profileImage} alt={props.user.messageTitle}>
        {!props.user.profileImage || props.user.profileImage === '' ? '' : ''}
      </Avatar>
    </div>*/}

    <ListItemText
      classes={{
        root: 'min-w-px px-16',
        primary: 'font-medium text-14',
        secondary: 'truncate',
      }}
      
      primary={props.user.messageTitle &&props.user.messageTitle }
      secondary={props.user.messageText &&props.user.messageText.slice(0,34) + "..."}
      // secondary={props.contact.mood}
    />

    {/* {props.contact.chatId && ( */}
      <div className="flex flex-col justify-center items-end">
      {/* {props.contact.lastMessageTime && ( */}
        <Typography
          className="whitespace-nowrap mb-8 font-medium text-12"
          color="textSecondary"
        >
          {/* {format(new Date(props.contact.lastMessageTime), 'PP')} */}
          {format(new Date('2024-08-20'/*props.user.time*/), 'PP')}
        </Typography>
      {/* )} */}

      {/* Show Badge if message is unread */}
      {/* {props.contact.unread && (
        <div
          className={clsx(
            classes.unreadBadge,
            'flex items-center justify-center min-w-24 h-24 rounded-full font-medium text-12 text-center'
          )}
        >
          {props.contact.unread}
        </div>
      )} */}
     {/* Show accept / reject buttons */}
    
     {/* <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group"> */}
     {/* <ButtonGroup color="secondary" aria-label="outlined secondary button group"> */}
     {
      props.user.status == 'pending' ?
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button style={{color: 'green'}} onClick={() => accRejInvite(props.user, 'accepted')}>Accept</Button>
        <Button style={{color: 'red'}} onClick={() => accRejInvite(props.user, 'rejected')}>Reject</Button>
     </ButtonGroup> : null
     }

{
      props.user.status == 'accepted' ?
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button style={{color: 'orange'}} onClick={() => {/*unMatch(props.user)*/}}>UnMatch</Button>
     </ButtonGroup> : null
     }

    </div>
    {/* )} */}
  </ListItem>
  );
}

export default AnnouncementListItem;
