import React, { useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {Stepper, Step, StepButton, TextField, Button, Divider, Grid, Select, MenuItem } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import Step1 from './Step1';
import Step2 from './Step2'; // Import Step2 component
import Step3 from './Step3'; // Import Step3 component
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { createStudent, uploadDocImages } from 'src/redux/actions/student.action';
import { notifyErrorFxn } from 'src/utils/toast-fxn';
import { saveThemeColor, saveThemeImage,saveThemeColorUnsaved, saveThemeImageUnsaved,  saveThemeImageBlob, saveHolidayEnd, saveHolidayStart, saveResumptionDate } from 'src/redux/reducers/settings.slice';
import BONLOGO from 'src/assets/images/logo.png'




const steps = ['Informations de Base', 'Information Additionnelle', 'Téléchargements de Docs'];


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '4rem',
    paddingRight: '4rem',
  },
  searchInput: {
    background: 'white',
    border: '1px solid #00000026',
    padding: '0px',
    borderRadius: '8px',
    // marginRight: theme.spacing(2),
    width: '100%',
    minWidth: '100%',
    '& .MuiInputBase-input': {
      color: 'grey',
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'grey',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'grey',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'grey',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey',
    },
  },
  searchButton: {
    color: '#fff',
    padding: '15px',
    minWidth: '45%',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export default function SettingsDateBox() {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const [state, setState] = useState({
    fname:  "",
    lname: "",
    dob: "",
    gender: "",
    studentshipType: "",
    registrationId: "",
    class: "",
    section: "",
    guardianName: "",
    bloodGroup: "",
    religion: "",
    phoneNumber: "",
    email: "",
    skinColor: "",
    eyeColor: "",
    height: "",
    Nationalité: "",
    admissionDate: "",
    admissionTerminated: "",
    medicalHistory: "",
    specialInstruction: ""
  })

  const {themeColorUnsaved,themeImageUnsaved} = useSelector((state)=>state.settings)

  let currentDate = new Date();

// Extract year, month, and day from the Date object
let year = currentDate.getFullYear(); // Get the current year (YYYY)
let endYear = year+1 // Get the current year (YYYY)
let month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Get the current month (MM), adding 1 because months are zero-indexed
let day = ('0' + currentDate.getDate()).slice(-2); // Get the current day (DD)

// Create the formatted date string "YYYY-MM-DD"
let formattedDate = `${year}-${month}-${day}`;
let endFormattedDate = `${endYear}-${month}-${day}`;

  const [resumptionDate,setResumptionDate] = useState(formattedDate)
  const [holidayStart,setHolidayStart] = useState(formattedDate)
  const [holidayEnd,setHolidayEnd] = useState(formattedDate)




 

  const submitDates = () =>{

   

    if(new Date(holidayEnd) <  new Date(holidayStart)){

      console.log("HOLIDAY ENDS B4 IT STARTS!")

      notifyErrorFxn("holiday ending should not be before holiday start!")

    }else{
      dispatch(saveHolidayStart(holidayStart))
      dispatch(saveHolidayEnd(holidayEnd))

    }


  }


  useEffect(()=>{
  
  submitDates()
  
},[holidayEnd])
  

  console.log("CAN I GET A DATE STRING?-->",new Date())

  const [selectedFile4, setSelectedFile4] = useState({selectedFile: [], selectedFileName: []});
const [file4, setFile4] = useState();
const handleselectedFile4 = event => {
  setSelectedFile4({
      selectedFile: event.target.files[0],
      selectedFileName: event.target.files[0].name
  });
  setFile4(URL.createObjectURL(event.target.files[0]));

dispatch(saveThemeImageUnsaved(URL.createObjectURL(event.target.files[0])))
dispatch(saveThemeImageBlob(event.target.files[0]))
};

  const [studentPassportFile, setStudentPassportFile] = useState({selectedFile: [], selectedFileName: []});
  const [anotherFieldFile, setAnotherFieldFile] = useState({selectedFile: [], selectedFileName: []});
  const [mothersIdFile, setMothersIdFile] = useState({selectedFile: [], selectedFileName: []});
  const [certificateFile, setCertificateFile] = useState({selectedFile: [], selectedFileName: []});
  const [medicalRecordFile, setMedicalFile] = useState({selectedFile: [], selectedFileName: []});

  const [surveyAnswer,setSurveyAnswer] = useState('')


  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });
  }

  const handleStudentPassportFile = event => {
    setStudentPassportFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
};
  const handleAnotherFieldFile = event => {
    setAnotherFieldFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
};
  const handleMothersIdFile = event => {
    setMothersIdFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
};
  const handleCertificateFile = event => {
    setCertificateFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
};
  const handleMedicalFile = event => {
    setMedicalFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
};


  // Helper function to render the appropriate step content based on the activeStep
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Step1 state={state} handleChange={handleChange} />;
      case 1:
        return <Step2 state={state} handleChange={handleChange} />;
      case 2:
        return <Step3 
        studentPassportFile={studentPassportFile}
        handleStudentPassportFile={handleStudentPassportFile}
        anotherFieldFile={anotherFieldFile}
        handleAnotherFieldFile={handleAnotherFieldFile}
        mothersIdFile={mothersIdFile}
        handleMothersIdFile={handleMothersIdFile}
        certificateFile={certificateFile}
        handleCertificateFile={handleCertificateFile}
        medicalRecordFile={medicalRecordFile}
        handleMedicalFile={handleMedicalFile}
        />;
      default:
        return 'Unknown step';
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const files = [
      studentPassportFile.selectedFile,
      anotherFieldFile.selectedFile,
      mothersIdFile.selectedFile,
      certificateFile.selectedFile,
      medicalRecordFile.selectedFile,
    ].filter(file => file); 
    
    try {
      setLoading(true);
     const urls = await Promise.all(files.map((file) => {
        if (file) {
          return dispatch(uploadDocImages(file));
        }
        return null;
      }));

  
      const [studentPassportFileUrl, anotherFieldFileUrl, mothersIdFileUrl, certificateFileUrl, medicalRecordFileUrl] = urls;
  
      const studentData = { state,  studentPassportFileUrl, anotherFieldFileUrl, mothersIdFileUrl, certificateFileUrl, medicalRecordFileUrl};
      dispatch(createStudent(studentData, navigate, setLoading));
    } catch (error) {
      // setLoading(false);
      console.error("Error uploading images: ", error);
      // notifyErrorFxn("Error occured uploading Images");
    }
  };
  

  return (
    <Box sx={{ width: '100%' }}>
    {/*  <div style={{ display: 'flex', width: '100%' }}>
  <div style={{ flex: 1 }}>
    <Stepper nonLinear activeStep={activeStep} style={{ border: '0px solid red', background: 'white' }}>
      {steps.map((label, index) => (
        <Step key={label} completed={completed[index]}>
          <StepButton color="inherit" onClick={handleStep(index)}>
            {label}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  </div>
</div> */}

      <div>
        {allStepsCompleted() ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </>
        ) : (
          <>
            <Typography sx={{ mt: 2, mb: 1, py: 1, ml: 5, color: '#000000', fontSize: '18px' }}>
              <b>{activeStep === 0 && 'Événements'}</b>
            </Typography>
           {/*<Divider />*/}
            <br />
            {/* Render the appropriate step content */}
            <Grid container style={{display:"flex",justifyContent:"center",gap:"2rem"}}  spacing={4}>
        <Grid item xs={5}>
          <Typography variant="subtitle1" style={{marginBottom:"1rem"}}>Nom de l'événement </Typography>
         
          <TextField
            name="event name"
            placeholder="Color"
            fullWidth
            value={resumptionDate}
            onChange={(e)=>{setResumptionDate(e.target.value);dispatch(saveResumptionDate(e.target.value))}}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
                
              style:{
               
                width:"100%",
                height:"3rem",
                backgroundColor:"white",
                border:"0px solid lightgrey",
                padding:"10px"
              }
            }}
          />
  
             
 
        


      {/* <input type="date" id="start"
         name="Resumption-Date"
         value={resumptionDate}
         onChange={(e)=>{setResumptionDate(e.target.value);dispatch(saveResumptionDate(e.target.value))}}
         min={formattedDate}
         max={endFormattedDate}
        
         style={{
               
          width:"100%",
          height:"3rem",
          backgroundColor:"white",
          border:"1px solid lightgrey",
          borderRadius:"5px",
          padding:"10px"
         }}
            
        />*/}
   




        </Grid>
        <Grid item xs={5} style={{position:"relative"}}>
           <div style={{display:"flex",justifyContent:"flex-start"}}>
          <Typography variant="subtitle1" style={{marginBottom:"1rem",width:"62%"}}>Date de début</Typography>

          <Typography variant="subtitle1" style={{marginBottom:"1rem",width:"38%"}}>Date de fin</Typography>
          </div>


         <div style={{width:"100%",display:"flex",gap:"15px"}}>

      
         <input type="date" id="holiday-start"
         name="Holiday-start"
         value={holidayStart}
         onChange={(e)=>{setHolidayStart(e.target.value)}}
         min={formattedDate}
         max={endFormattedDate}
        
         style={{
               
          width:"40%",
          height:"3rem",
          backgroundColor:"white",
          border:"1px solid lightgrey",
          borderRadius:"5px",
          padding:"10px"
         }}
            
            />

   
       <div style={{width:"20%",display:"flex",justifyContent:"center",alignItems:"center"}}>
       
         <div style={{borderBottom:"3px solid black",width:"2rem"}}></div>
          
       </div>




       <input type="date" id="holiday-end"
         name="Holiday-end"
         value={holidayEnd}
         onChange={(e)=>{setHolidayEnd(e.target.value)}}
         min={formattedDate}
         max={endFormattedDate}
        
         style={{
               
          width:"40%",
          height:"3rem",
          backgroundColor:"white",
          border:"1px solid lightgrey",
          borderRadius:"5px",
          padding:"10px"
         }}
            
            />

         </div>
         
         
         
         
         {/* <TextField
            name="lname"
            placeholder="Logo"
            component="label"
            fullWidth
            value={state.lname}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,

                
              style:{
               
                width:"100%",
                height:"3rem",
                backgroundColor:"white",
                border:"0px solid lightgrey",
                padding:"10px"
              }
            }}
          />

          <div style={{backgroundColor:"white",height:"2rem",width:"5.3rem",position:"absolute",top:"5rem",left:"2.1rem",zIndex:"2"}}></div>
           
                <input
                  type="file"
                  style={{ display: 'flex',width:"100%",position:"relative",top:"-2rem",left:"0.2rem",opacity:!selectedFile4.selectedFileName?"0":"1",fontFamilt:"Arial" }}
                  onChange={handleselectedFile4}
                   />
            */}

         </Grid>
        </Grid>



            <br/><br />
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
               <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
               <Button
                variant="contained"
                disabled={activeStep === 0 || loading}
                onClick={handleBack}
                style={{
                  display: activeStep === 0 ? 'none' : 'inline-block',
                  minWidth: '125px',
                  backgroundColor: 'transparent',
                  border: '1px solid #000000',
                  color: '#000000',
                  marginLeft: '4rem',
                  paddingTop: '15px',
                  paddingBottom: '15px',
                  paddingLeft: '20px',
                }}
              >
                Previous
              </Button>

              

              </Grid>
              <Box sx={{ flex: '1 1 auto' }} />
            </Box>
          </>
        )}
      </div>
    </Box>
  );
}
