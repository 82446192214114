import { db, fb, auth, storage } from '../../config/firebase';
import { clearUser, loginFailed, loginSuccess, logoutFxn, signupFailed, storeUserData } from '../reducers/auth.slice';
import {

    saveCartToProcess,
        saveMostRecentOrderAmount,
        saveMostRecentOrderId,
        saveMostRecentPayToken,

} from '../reducers/settings.slice';

import { v4 as uuidv4 } from 'uuid';
import { notifyErrorFxn, notifySuccessFxn } from 'src/utils/toast-fxn';
import { saveThemeColor,saveThemeImage } from '../reducers/settings.slice';


/*=====TOKEN IS BEING SAVED TO DB SO THAT THEY CAN BE CALLED ON ORANGE CALLBACK PAGE  ======*/
export const savePayTokenToDatabase = (uid,pay_token,orderAmount,orderId) => async (dispatch) => {
    
     db.collection("schools")
     .doc(uid)
      .update({
        
        mostRecentOrderAmount:orderAmount,
        mostRecentPayToken:pay_token,
        mostRecentOrderId:orderId,
        
      })
      .then((snapshot) => {
       
      
       
      
    }).catch((error) => {
      //console.log("Error getting document:", error);
      notifyErrorFxn("ISSUE WITH STORAGE OF CART")
      
    });
  };



  /*=====TOKEN IS CLEARED FROM DB AFTER VISITING ORANGE (OM) CALLBACK PAGE  ======*/
export const clearPayTokenFromDatabase = (uid,pay_token,orderAmount,orderId) => async (dispatch) => {
   
     db.collection("users")
     .doc(uid)
      .update({
        
        mostRecentOrderAmount:0,
        mostRecentPayToken:'',
        mostRecentOrderId:'',
        subscriptionPaid:true
        
      })
      .then((snapshot) => {
       
       //console.log("---->THE Order ID HAS BEEN CLEARED FROM THE DB, READY FOR USE IN THE CALLBACK PAGE<-----");
      
      
    }).catch((error) => {
      //console.log("Error getting document:", error);
      notifyErrorFxn("ISSUE WITH CLEARING TOKEN FROM DATABASE AND UPDATING STATUS TO PAID")
      
    });
  };



  export const fetchCartToProcessFromUser = (uid) => async (dispatch) => {
  
     db.collection("schools")
     .doc(uid)
      .get()
      .then((doc) => {
       
  
        if (doc.exists) {
          // //console.log("User Data:", doc.data());
          //dispatch(saveCartToProcess(doc.data().cartInProgress)); -- NO CART HERE, WE ARE JUST CHECKING THE PAYMENT
          dispatch(saveMostRecentOrderAmount(doc.data().mostRecentOrderAmount));
          dispatch(saveMostRecentOrderId(doc.data().mostRecentOrderId));
          dispatch(saveMostRecentPayToken(doc.data().mostRecentPayToken));
  
        }
        else {
          console.log("THE USER WE ARE TRYING TO GET THEIR MOST UP TO DATE CART, SEEMS TO BE MISSING")
        }
      
  
  
      
    }).catch((error) => {
      console.log("Error getting document:", error);
     
      console.log("WE HAVE CART TO PROGRESS, NOW WE HAVE AN ISSUE IN DISPENSING IT TO PURCHASED COURSES")
     // dispatch(isItLoading(false));
    });
  };
  