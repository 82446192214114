import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography, Button, Select, MenuItem } from '@mui/material';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { deleteStudent } from 'src/redux/actions/student.action';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '4rem',
    paddingRight: '4rem',
  },
  searchInput: {
    background: 'white',
    border: '1px solid #00000026',
    padding: '10px',
    borderRadius: '8px',
    // marginRight: theme.spacing(2),
    width: '100%',
    minWidth: '100%',
    '& .MuiInputBase-input': {
      color: 'grey',
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'grey',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'grey',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'grey',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey',
    },
  },
  searchButton: {
    color: '#fff',
    padding: '15px',
    minWidth: '45%',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const EditDocInfo = ({
   state,  
   studentPassportFile,
  handleStudentPassportFile,
  anotherFieldFile,
  handleAnotherFieldFile,
  mothersIdFile,
  handleMothersIdFile,
  certificateFile,
  handleCertificateFile,
  medicalRecordFile,
  handleMedicalFile,
  profileImageFile,
  handleProfileImageFile,
   handleChange, 
   handleUpdate, 
   loading}) => {
  const classes = useStyles();
 const dispatch = useDispatch();
 const navigate = useNavigate();
  
console.log("STATE:::", state);


const { themeColor } = useSelector((state) => state.settings);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant="subtitle1">Informations sur les Documents</Typography>
      { /*<div>
        <Button
          variant="contained"
          style={{
            minWidth: '125px',
            backgroundColor: 'transparent',
            border: '1px solid #000000',
            paddingTop: '15px',
            paddingBottom: '15px',
            paddingLeft: '20px',
            color: '#000000'
          }}
          disabled={loading}
          onClick={handleUpdate}
        >
          {loading ? "Chargement..." : "Modifier"}
        </Button>
        <Button
          variant="contained"
          style={{
            minWidth: '125px',
            backgroundColor: themeColor?themeColor:"#D72A34",
            marginLeft: '1rem',
            paddingTop: '15px',
            paddingBottom: '15px',
            paddingLeft: '20px',
            color: 'white'
          }}
        >
          Supprimer les informations
        </Button>
        </div> */}
    </div>
     <br/>
     <div className={classes.root}>
<Grid container spacing={4} alignItems="center">
  {/* First set of TextField and Button */}
  <Grid item container xs={6} direction="row" alignItems="center">
    <Grid item xs={8}>
      <Typography variant="subtitle1">
        Carte d'étudiant (Format: PNG, JPEG, JPG)
      </Typography>
      <TextField
        name="fname"
        placeholder="sélectionner un fichier"
        disabled
        fullWidth
        value={studentPassportFile.selectedFileName ? studentPassportFile.selectedFileName : state.studentPassportFile}
        onChange={handleChange}
        className={classes.searchInput}
        InputProps={{
          disableUnderline: true,
        }}
      />
    </Grid>
    <Grid item xs={4}>
      <Button
        component="label"
        variant="contained"
        style={{
          minHeight: '52px',
          minWidth: '145px',
          backgroundColor: '#000000',
          marginTop: '20px',
        }}
      >
        <b>Choisir</b>
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={handleStudentPassportFile}
        />
      </Button>
    </Grid>
  </Grid>

  {/* Second set of TextField and Button */}
  <Grid item container xs={6} direction="row" alignItems="center">
    <Grid item xs={8}>
      <Typography variant="subtitle1">
        Livret Scolaire (Format: PNG, JPEG, JPG)
      </Typography>
      <TextField
        name="anotherField"
        placeholder="sélectionner un fichier"
        disabled
        fullWidth
        value={anotherFieldFile.selectedFileName ? anotherFieldFile.selectedFileName : state.anotherFieldFile}
        onChange={handleChange}
        className={classes.searchInput}
        InputProps={{
          disableUnderline: true,
        }}
      />
    </Grid>
    <Grid item xs={4}>
      <Button
        component="label"
        variant="contained"
        style={{
          minHeight: '52px',
          minWidth: '145px',
          backgroundColor: '#000000',
          marginTop: '20px',
        }}
      >
        <b>Choisir</b>
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={handleAnotherFieldFile}
        />
      </Button>
    </Grid>
  </Grid>

  {/* Third set of TextField and Button */}
  <Grid item container xs={6} direction="row" alignItems="center">
    <Grid item xs={8}>
      <Typography variant="subtitle1">
      Carte d'identité des parents (Format: PNG,JPEG, JPG)
      </Typography>
      <TextField
        name="fname"
        placeholder="sélectionner un fichier"
        disabled
        fullWidth
        value={mothersIdFile.selectedFileName ? mothersIdFile.selectedFileName : state.mothersIdFile}
        onChange={handleChange}
        className={classes.searchInput}
        InputProps={{
          disableUnderline: true,
        }}
      />
    </Grid>
    
    <Grid item xs={4}>
      <Button
        component="label"
        variant="contained"
        style={{
          minHeight: '52px',
          minWidth: '145px',
          backgroundColor: '#000000',
          marginTop: '20px',
        }}
      >
        <b>Choisir</b>
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={handleMothersIdFile}
        />
      </Button>
    </Grid>
  </Grid>

  {/* Fouth set of TextField and Button */}
  <Grid item container xs={6} direction="row" alignItems="center">
    <Grid item xs={8}>
      <Typography variant="subtitle1">
      Tous les certificats (Format: PDF)
      </Typography>
      <TextField
        name="anotherField"
        placeholder="sélectionner un fichier"
        disabled
        fullWidth
        value={certificateFile.selectedFileName ? certificateFile.selectedFileName : state.certificateFile}
        onChange={handleChange}
        className={classes.searchInput}
        InputProps={{
          disableUnderline: true,
        }}
      />
    </Grid>
    <Grid item xs={4}>
      <Button
        component="label"
        variant="contained"
        style={{
          minHeight: '52px',
          minWidth: '145px',
          backgroundColor: '#000000',
          marginTop: '20px',
        }}
      >
        <b>Choisir</b>
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={handleCertificateFile}
        />
      </Button>
    </Grid>
  </Grid>

  {/* Fifth set of TextField and Button */}
  <Grid item container xs={6} direction="row" alignItems="center">
    <Grid item xs={8}>
      <Typography variant="subtitle1">
      Tous les dossiers médicaux pertinents (Format: PDF)
      </Typography>
      <TextField
        name="anotherField"
        placeholder="sélectionner un fichier"
        disabled
        fullWidth
        value={medicalRecordFile.selectedFileName ? medicalRecordFile.selectedFileName : state.medicalRecordFile}
        onChange={handleChange}
        className={classes.searchInput}
        InputProps={{
          disableUnderline: true,
        }}
      />
    </Grid>
    <Grid item xs={4}>
      <Button
        component="label"
        variant="contained"
        style={{
          minHeight: '52px',
          minWidth: '145px',
          backgroundColor: '#000000',
          marginTop: '20px',
        }}
      >
        <b>Choisir</b>
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={handleMedicalFile}
        />
      </Button>
    </Grid>
  </Grid>




  {/* Sixth set of TextField and Buttons */}
  <Grid item container xs={6} direction="row" alignItems="center">
    <Grid item xs={8}>
      <Typography variant="subtitle1">
      Image de profil (Format: PNG,JPG,JPEG)
      </Typography>
      <TextField
        name="anotherField"
        placeholder="sélectionner un fichier"
        disabled
        fullWidth
        value={profileImageFile.selectedFileName ? profileImageFile.selectedFileName : state.profileImageFile}
        onChange={handleChange}
        className={classes.searchInput}
        InputProps={{
          disableUnderline: true,
        }}
      />
    </Grid>
    <Grid item xs={4}>
      <Button
        component="label"
        variant="contained"
        style={{
          minHeight: '52px',
          minWidth: '145px',
          backgroundColor: '#000000',
          marginTop: '20px',
        }}
      >
        <b>Choisir</b>
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={handleProfileImageFile}
        />
      </Button>
    </Grid>
  </Grid>
   
      
  <Grid item container xs={12} direction="row"  style={{marginBottom:"1rem"}}>
  <div>
  <Button
         onClick={()=>{dispatch(deleteStudent(state,navigate))}}
          variant="contained"
          style={{
            minWidth: '125px',
            backgroundColor: 'transparent',
            border: '1px solid #000000',
            paddingTop: '15px',
            paddingBottom: '15px',
            paddingLeft: '20px',
            color: '#000000'
          }}
          disabled={loading}
         
        >
          {loading ? "Chargement..." : "Supprimer"}
        </Button>
        <Button
         // onClick={()=>{dispatch(updateStudent(state))}}

          variant="contained"
          style={{
            minWidth: '125px',
            backgroundColor: themeColor?themeColor:"#D72A34",
            marginLeft: '1rem',
            paddingTop: '15px',
            paddingBottom: '15px',
            paddingLeft: '20px',
            color: 'white'
          }}
          onClick={handleUpdate}
        >
          Soumettre
        </Button>
      </div>
 </Grid>
    
</Grid>


</div>
    </>
  );
};

export default EditDocInfo;
