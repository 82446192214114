import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chatMessages: [],
  announcementDetails:{},
  inboxMessages: [],
  inboxDetails: null,
  isLoading: false,
  inboxShow:true,
  chatStarted: false,
  selectedChatUser: null,
  selectedAnnouncement: null,
  message: '',
  error: '',
};

const chatSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    fetchChatsPending: (state) => {
        state.isLoading = true;
        state.error = '';
        state.message = '';
      },
      fetchChatsSuccess: (state, action) => {
        state.isLoading = false;
        state.chatMessages = action.payload;
        state.error = '';
    },
    fetchAnnouncementsSuccess: (state, action) => {
      state.isLoading = false;
      state.announcementDetails = action.payload;
      state.error = '';
  },


    clearChatsSuccess: (state, action) => {
      state.isLoading = false;
      state.chatMessages = [];
      state.error = '';
  },

  clearAnnouncementsSuccess: (state, action) => {
    state.isLoading = false;
    state.announcement = [];
    state.error = '';
},
  showInbox: (state, action) => {
    state.isLoading = false;
    state.inboxShow= action.payload
    state.error = '';
},
    fetchChatsFailed: (state, { payload }) => {
       state.isLoading = false;
       state.error = payload.errorMessage;
    },
    setCurrentChat: (state, action) => {
       state.chatStarted = true;
       state.selectedChatUser = action.payload;
    },
    setCurrentAnnouncement: (state, action) => {
      state.chatStarted = true;
      state.selectedAnnouncement = action.payload;
   },
    fetchInboxMessages: (state, action) => {
      state.isLoading = false;
      state.inboxMessages = action.payload;
      state.error = '';
  },
  setInboxDetails: (state, action) => {
    state.inboxDetails = action.payload;
 },
    clearChat: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

const { actions, reducer } = chatSlice;

export const {
 fetchChatsPending,
 fetchChatsSuccess,
 clearChatsSuccess,
 showInbox,
 setCurrentAnnouncement,
 fetchAnnouncementsSuccess,
 clearAnnouncementsSuccess,
 fetchChatsFailed,
 setCurrentChat,
 fetchInboxMessages,
 setInboxDetails,
 clearChat,
} = actions;

export default reducer;


