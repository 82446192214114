import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chart } from "react-google-charts";

/*export const data = [
  ["Language", "Speakers (in millions)"],
  ["Mâle", 13],
  ["Femelle", 7],
];*/



export default function PieChartTwo({ headerOne, headerTwo, value,maleNumber,femaleNumber }) {
  const { user } = useSelector((state) => state.auth);
  const { themeColor,themeImage } = useSelector((state) => state.settings);

  const data = [
    ["Language", "Speakers (in millions)"],
    ["Mâle", maleNumber],
    ["Femelle", femaleNumber],
  ];
  
  
  console.log("COLOR FOR PIE CHART 2-->",themeImage)
//I BROUGHT OPTIONS ARRAY INTO THE PIECHART TWO COMPONENT, SO THAT I CAN GIVE THE COLOR THE THEME COLOR

   const options = {
    legend: "none",
    pieSliceText: "label",
    title: "",
    pieStartAngle: 100,
  colors: ["#D3D3D3", /*"#D72A34"*/themeColor?themeColor:"#D72A34"], 
    chartArea: { left: "10%", top: "10%", width: "80%", height: "80%" }, // Adjust the chart area to increase the size of the pie chart
    backgroundColor: { fill: "transparent" }, // Set the background color to transparent
  };



  return (
    <div style={{ background: "transparent", width: "100%", height: "400px" }}>
      <Chart
        chartType="PieChart"
       
        data={data}
        options={options}
        width={"100%"}
        height={"100%"}
      />
    </div>
  );
}
