import { useState, useEffect } from 'react';
import { Button, Typography, TextField, Grid,Container, FormControl, Select, MenuItem} from '@mui/material';
import {Box,Icon,CardMedia,CssBaseline, makeStyles} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateProfile, uploadImage, uploadProfileImage } from 'src/redux/actions/auth.action';
import { saveThemeColor, saveThemeImage } from 'src/redux/reducers/settings.slice';
import blankProfile from 'src/assets/images/blank-profile.png'
import { notifyErrorFxn } from 'src/utils/toast-fxn';


const useStyles = makeStyles((theme) => ({
  textField: {
  padding: '8px',
   border: '0px solid grey',
  },
  paper: {
    display: "flex",
    width: "auto",
  },
  grid: {
    width: "auto",
  },
  arrow: {
    padding: theme.spacing(3),
  },
  box: {
  //   padding: theme.spacing(3),
    paddingLeft: theme.spacing(8),
  },
}));

export default function ProfileInfo({studentData, handleUpdate, handleChange, loading}) {
  const classes = useStyles();
    const dispatch = useDispatch(); 
    const navigate = useNavigate();
    const location = useLocation();
    const { user,school } = useSelector((state) => state.auth);
    let today = new Date().toISOString().slice(0, 10);
    let nextMonth = new Date(today);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    let nextMonthDate = nextMonth.toISOString().slice(0, 10);

    const { themeColor } = useSelector((state) => state.settings);

    const [inputValue, setInputValue] = useState({
      target:{
        value:studentData?.amountWillingToPay
      }
    });
    const [typingTimer, setTypingTimer] = useState(null);
    const doneTypingInterval = 1400; // 1 second

   
    //THIS USE EFFECT IS FOR WAITING FOR USER TO FINISH TYPING THE AMOUNT THEN DETERMINING THAT ITS A NUMBER
    //WE DO THSI SO WE DONT SUBTRACT NAN FROM THE FEES OWED, IT MUST BE NUMBER
    useEffect(() => {
      // Clear the timer if it exists
      if (typingTimer) {
        clearTimeout(typingTimer);
      }
  
      // Set a new timer
      const timer = setTimeout(() => {
        console.log('User stopped typing,INPUT VALUE--->',inputValue);
        //handleChange(inputValue)


        if( (Number(inputValue.target.value) - Number(inputValue.target.value) === 0 )){

           handleChange(inputValue)
        }else{
          notifyErrorFxn('please make sure to enter a number value in this field')
        }

      }, doneTypingInterval);
  
      // Save the timer reference to clear it later
      setTypingTimer(timer);
  
      // Cleanup function to clear the timer when the component unmounts or input changes
      return () => clearTimeout(timer);
    }, [inputValue]); // Dependency array contains inputValue


    useEffect(()=>{
  
   if(!themeColor){
   dispatch(saveThemeColor( school && school.settings &&  school.settings.themeColor))
   dispatch(saveThemeImage(school && school.settings &&  school.settings.themeImage))
   }
  
  
    },[])
  

useEffect(() => {
  console.log("STUDENDT_DATA::", studentData);
}, [])


   
  return (
    <>
  <Container maxWidth="xl" style={{height: '35%', background: '#F9F9F9', border: '0px solid red' }}>
  <CssBaseline />
  <form>
    <Grid container spacing={2} style={{paddingTop: '2%'}}>
        <Grid item>
        <CardMedia
            style={{ border: '0.2px solid black', backgroundColor: '#fff', width: '210px' }}
            component="img"
            height="180"
            width="400"
           image={studentData.profileImageFile?studentData.profileImageFile:blankProfile}
            alt="IMG"
          />
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="div" style={{fontWeight: '600', fontSize: '25px', lineHeight: '36px', color: '#000000'}}>
                {studentData?.fname + ' ' + studentData?.lname}
              </Typography>
              <Typography style={{fontWeight: '400', fontSize: '20px', lineHeight: '22px', color: '#000000', marginBottom: '10px'}}>
              {studentData?.class}
              </Typography>
              <Typography style={{fontWeight: '400', fontSize: '20px', lineHeight: '22px', color: '#000000', marginBottom: '10px'}}>
                ID: {studentData?.studentId}
              </Typography>
              <Typography style={{fontWeight: '400', fontSize: '20px', lineHeight: '22px', color: '#000000', marginBottom: '10px'}}>
                Gardien: {studentData?.guardianName}
              </Typography>
              <Typography style={{fontWeight: '600', fontSize: '25px', lineHeight: '26px', color: themeColor, marginBottom: '10px'}}>
               <b>{studentData?.paymentStatus}</b>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs container direction="column" spacing={2}>
          <Grid item xs  style={{marginTop: '30px'}}>
          <FormControl sx={{ minWidth: 200 }}>
          <Select
            value={studentData.paymentStatus}
            name="paymentStatus"
            onChange={(e)=>{
              handleChange(e);
              if(e.target.value==='Payé'){
              handleUpdate(e)
              }
            }}
            displayEmpty
            label=""
            sx={{
              minHeight: 30,
              minWidth: 250,
              p: 1,
            }}
          >
            <MenuItem value={"Impayé"}>Impayé</MenuItem>
            <MenuItem value={"Payé"}>Payé</MenuItem>
          </Select>
        </FormControl>
        <br/><br/>

      <Grid container xs={6} style={{display:"flex",alignItems:"center",gap:"1.2rem"}} spacing={1}>
       
        <Grid item xs={3}>
          <Typography style={{fontSize:"1.3rem"}} variant="subtitle1">Amount</Typography>
         
        </Grid>

        <Grid item xs={5.4}>
         
          <TextField
            name="amountWillingToPay"
            placeholder="Amount"
          
             value={inputValue.target.value}
            onChange={(e)=>{
              
            if(e.target && e.target.value === ''  ||e.target && typeof(Number(e.target.value)) === 'number'){

              setInputValue(e)
            }else{
              notifyErrorFxn('please make sure to enter a number value in this field')
            }
               }
            }
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
              style:{
                height:"3rem",
                paddingLeft:"0.5rem"
              }
            }}
          />
        </Grid>

      </Grid>

        <br/><br/>


          <FormControl sx={{ minWidth: 200 }}>
          <Button onClick={handleUpdate} disabled={loading} component="label" variant="contained" style={{ minHeight: '45px', minWidth: '250px', backgroundColor: 'black' }}>
            <b>{loading ? 'Chargement...' : 'MISE À JOUR'}</b>
          </Button>
        </FormControl>
            </Grid>
           
          </Grid>
      
        </Grid>
      </Grid>
  </form>
</Container>

    </>
  );
}
