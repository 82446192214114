
import React, { useState} from 'react';
import Box from '@mui/material/Box';
import {Stepper, Step, StepButton, TextField, Button, Divider, Grid, Select, MenuItem, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, Chip } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import Step1 from './Step1';
import Step2 from './Step2'; // Import Step2 component
import Step3 from './Step3'; // Import Step3 component
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { createStudent, uploadDocImages } from 'src/redux/actions/student.action';
import { notifyErrorFxn } from 'src/utils/toast-fxn';
import { saveThemeColor,
   saveThemeImage,
   saveThemeColorUnsaved,
    saveThemeImageUnsaved, 
     saveThemeImageBlob,
      saveAPEAE,
       saveInstallment,
       saveTuitionFee,
       saveAdmissionFee,
       saveReadmissionFee,
        saveOnlinePayment,
         saveOrangeMerchantApi,
          saveSelectedLevelToAddToSchool,
           saveTuitionFeeAtLevel,
         saveTermElementary,
         saveTermSecondary,
         saveSubjectsForLevel,
         saveExamsPerTerm,

           } from 'src/redux/reducers/settings.slice';
import BONLOGO from 'src/assets/images/logo.png'

const steps = ['Informations de Base', 'Information Additionnelle', 'Téléchargements de Docs'];


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '4rem',
    paddingRight: '4rem',
  },
  searchInput: {
    background: 'white',
    border: '1px solid #00000026',
    padding: '0px',
    borderRadius: '8px',
    // marginRight: theme.spacing(2),
    width: '100%',
    minWidth: '100%',
    '& .MuiInputBase-input': {
      color: 'black',
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'grey',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'grey',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'grey',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey',
    },
  },
  searchButton: {
    color: '#fff',
    padding: '15px',
    minWidth: '45%',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export default function SettingsLevelsBox() {



  const {themeColorUnsaved,
    themeImageUnsaved,
    orangeMerchantApi,
     onlinePayment,
     tuitionFeeAtLevel,
     selectedLeveltoAddToSchool,
     subjectsForLevel,
     termSecondary,
     termElementary,
     examsPerTerm
  } = useSelector((state)=>state.settings)


console.log('selectedleveltoAddToSchool IS NOW-->',selectedLeveltoAddToSchool)

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [loading, setLoading] = useState(false);
  const [merchantPayment, setMerchantPayment] = useState(false);

  const [selectedClass,setSelectedClass] = useState()
  const [selectedTermElementary,setSelectedTermElementary] = useState('1')
  
  const [numberOfExamsPerTerm,setNumberOfExamsPerTerm] = useState(examsPerTerm)

  const [selectedTermSecondary,setSelectedTermSecondary] = useState('1')
  const [selectedSubject,setSelectedSubject] = useState('Mathematics')


  const [subjectSavingArr,setSubjectSavingArr] = useState([])
  const [radiologyIdArr,setRadiologyIdArr] = useState([])

  const handleClick = () => {
    console.info('You clicked the Chip.');
  };

  const handleDeleteRad = (tbr,tbrId) => {
    

    let placeholder =   subjectSavingArr.filter((item)=>(item !== tbr))
   let placeholder2 =   radiologyIdArr.filter((item)=>(item !== tbrId))
  
  
     setSubjectSavingArr([...placeholder])
    setRadiologyIdArr([...placeholder2])
  };



  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const [state, setState] = useState({
    fname:  "",
    lname: "",
    dob: "",
    gender: "",
    studentshipType: "",
    registrationId: "",
    class: "",
    section: "",
    guardianName: "",
    bloodGroup: "",
    religion: "",
    phoneNumber: "",
    email: "",
    skinColor: "",
    eyeColor: "",
    height: "",
    nationality: "",
    admissionDate: "",
    admissionTerminated: "",
    medicalHistory: "",
    specialInstruction: "",

    installment:'',
    apeae:''
  })



  const [selectedFile4, setSelectedFile4] = useState({selectedFile: [], selectedFileName: []});
const [file4, setFile4] = useState();
const handleselectedFile4 = event => {
  setSelectedFile4({
      selectedFile: event.target.files[0],
      selectedFileName: event.target.files[0].name
  });
  setFile4(URL.createObjectURL(event.target.files[0]));

dispatch(saveThemeImageUnsaved(URL.createObjectURL(event.target.files[0])))
dispatch(saveThemeImageBlob(event.target.files[0]))
};

  const [studentPassportFile, setStudentPassportFile] = useState({selectedFile: [], selectedFileName: []});
  const [anotherFieldFile, setAnotherFieldFile] = useState({selectedFile: [], selectedFileName: []});
  const [mothersIdFile, setMothersIdFile] = useState({selectedFile: [], selectedFileName: []});
  const [certificateFile, setCertificateFile] = useState({selectedFile: [], selectedFileName: []});
  const [medicalRecordFile, setMedicalFile] = useState({selectedFile: [], selectedFileName: []});

  const [surveyAnswer,setSurveyAnswer] = useState('')

  const [installment,setInstallment] = useState('')

  const [APEAE,setAPEAE] = useState('')

  const [tuitionFeeForLevel,setTuitionFeeForLevel] = useState('')
  
  const [admissionFee,setAdmissionFee] = useState('')
 const [readmissionFee,setReadmissionFee] = useState('')

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });
  }

  const handleStudentPassportFile = event => {
    setStudentPassportFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
};
  const handleAnotherFieldFile = event => {
    setAnotherFieldFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
};
  const handleMothersIdFile = event => {
    setMothersIdFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
};
  const handleCertificateFile = event => {
    setCertificateFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
};
  const handleMedicalFile = event => {
    setMedicalFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
};


  // Helper function to render the appropriate step content based on the activeStep
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Step1 state={state} handleChange={handleChange} />;
      case 1:
        return <Step2 state={state} handleChange={handleChange} />;
      case 2:
        return <Step3 
        studentPassportFile={studentPassportFile}
        handleStudentPassportFile={handleStudentPassportFile}
        anotherFieldFile={anotherFieldFile}
        handleAnotherFieldFile={handleAnotherFieldFile}
        mothersIdFile={mothersIdFile}
        handleMothersIdFile={handleMothersIdFile}
        certificateFile={certificateFile}
        handleCertificateFile={handleCertificateFile}
        medicalRecordFile={medicalRecordFile}
        handleMedicalFile={handleMedicalFile}
        />;
      default:
        return 'Unknown step';
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const files = [
      studentPassportFile.selectedFile,
      anotherFieldFile.selectedFile,
      mothersIdFile.selectedFile,
      certificateFile.selectedFile,
      medicalRecordFile.selectedFile,
    ].filter(file => file); 
    
    try {
      setLoading(true);
     const urls = await Promise.all(files.map((file) => {
        if (file) {
          return dispatch(uploadDocImages(file));
        }
        return null;
      }));

  
      const [studentPassportFileUrl, anotherFieldFileUrl, mothersIdFileUrl, certificateFileUrl, medicalRecordFileUrl] = urls;
  
      const studentData = { state,  studentPassportFileUrl, anotherFieldFileUrl, mothersIdFileUrl, certificateFileUrl, medicalRecordFileUrl};
      dispatch(createStudent(studentData, navigate, setLoading));
    } catch (error) {
      // setLoading(false);
      console.error("Error uploading images: ", error);
      // notifyErrorFxn("Error occured uploading Images");
    }
  };
  

  return (
    <Box sx={{ width: '100%' }}>
      
    {/*  <div style={{ display: 'flex', width: '100%' }}>
  <div style={{ flex: 1 }}>
    <Stepper nonLinear activeStep={activeStep} style={{ border: '0px solid red', background: 'white' }}>
      {steps.map((label, index) => (
        <Step key={label} completed={completed[index]}>
          <StepButton color="inherit" onClick={handleStep(index)}>
            {label}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  </div>
</div> */}

      <div>
        {allStepsCompleted() ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </>
        ) : (
          <>
            <Typography sx={{ mt: 2, mb: 1, py: 1, ml: 5, color: '#000000', fontSize: '18px' }}>
              <b>{activeStep === 0 && 'Frais et Conditions'}</b>
            </Typography>
           {/*<Divider />*/}
            <br />



            <Grid container style={{display:"flex",justifyContent:"center",gap:"2rem"}}  spacing={4}>
       
            <Grid item xs={5}>
         <Typography variant="subtitle1" style={{marginBottom:"1rem"}}>
         Niveau</Typography>
        

       <FormControl sx={{ width: "100%", background: 'white' }}>
          <Select
            value={selectedClass}
            onChange={ (e) => {console.log("e.target.value is --->",e.target.value)
            
         if(subjectSavingArr.length > 0){
           notifyErrorFxn('Please Clear out all chosen subjects before changing level.')
         }
        else{
              
             setSelectedSubject('');
           
            e.target.value==="Level 1"? setSelectedClass("Niveau 1"):
            e.target.value==="Level 2"? setSelectedClass("Niveau 2"):
            e.target.value==="Level 3"? setSelectedClass("Niveau 3"):
            e.target.value==="Level 4"? setSelectedClass("Niveau 4"):
            e.target.value==="Level 5"? setSelectedClass("Niveau 5"):
            e.target.value==="Level 6"? setSelectedClass("Niveau 6"):
            e.target.value==="Level 7"? setSelectedClass("Niveau 7"):
            e.target.value==="Level 8"? setSelectedClass("Niveau 8"):
            e.target.value==="Level 9"? setSelectedClass("Niveau 9"):
            e.target.value==="Level 10"? setSelectedClass("Niveau 10"):
            e.target.value==="Level 11"? setSelectedClass("Niveau 11"):
            e.target.value==="Level 12"? setSelectedClass("Niveau 12"):
             setSelectedClass("Niveau 13")   ;

              dispatch(saveSelectedLevelToAddToSchool(e.target.value ) )
            
            }
            
           }
            }
            displayEmpty
            label=""
            sx={{
            //   minHeight: 30,
              minWidth: 140,
              p: 1,
            }}
          >
            <MenuItem value="">
            Sélectionner une Niveau
            </MenuItem>
        <MenuItem value={'Level 1'}>Niveau 1</MenuItem>
        <MenuItem value={'Level 2'}>Niveau 2</MenuItem>
        <MenuItem value={'Level 3'}>Niveau 3</MenuItem>
        <MenuItem value={'Level 4'}>Niveau 4</MenuItem>
        <MenuItem value={'Level 5'}>Niveau 5</MenuItem>
        <MenuItem value={'Level 7'}>Niveau 7</MenuItem>
        <MenuItem value={'Level 8'}>Niveau 8</MenuItem>
        <MenuItem value={'Level 9'}>Niveau 9</MenuItem>
        <MenuItem value={'Level 10'}>Niveau 10</MenuItem>
        <MenuItem value={'Level 11'}>Niveau 11</MenuItem>
        <MenuItem value={'Level 12'}>Niveau 12</MenuItem>
        <MenuItem value={'Level 13'}>Niveau 13</MenuItem>
          </Select>
        </FormControl>

       </Grid>



       <Grid item xs={5}>
         <Typography variant="subtitle1" style={{marginBottom:"1rem"}}>Frais</Typography>
         {<TextField
           name="Frais"
           placeholder="Frais"
           fullWidth
           value={tuitionFeeForLevel}
           onChange={(e)=>{setTuitionFeeForLevel(e.target.value);dispatch(saveTuitionFeeAtLevel(e.target.value)) }}
           className={classes.searchInput}
           InputProps={{
             disableUnderline: true,
               
             style:{
              
               width:"100%",
               height:"3rem",
               backgroundColor:"white",
               border:"0px solid lightgrey",
               padding:"10px"
             }
           }}
         />}

       </Grid>


     



       </Grid>

        <br/>
        <br/>
            {/* Render the appropriate step content */}

      


     <Grid container style={{display:"flex",justifyContent:"center",gap:"2rem"}}  spacing={4}>
        <Grid item xs={5}>
          <Typography variant="subtitle1" style={{marginBottom:"1rem"}}>Elementary (Terms) </Typography>
          <FormControl sx={{ width: "100%", background: 'white' }}>
          <Select
            value={selectedTermElementary}
            onChange={(e) => {setSelectedTermElementary(e.target.value);dispatch(saveTermElementary(e.target.value))} }
            displayEmpty
            label=""
            sx={{
            //   minHeight: 30,
              minWidth: 140,
              p: 1,
            }}
          >
            <MenuItem value="">
            Sélectionner une Terme
            </MenuItem>
        <MenuItem value={'1'}>Terme 1</MenuItem>
        <MenuItem value={'2'}>Terme 2</MenuItem>
       
          </Select>
        </FormControl>



        </Grid>
        <Grid item xs={5}>
          <Typography variant="subtitle1" style={{marginBottom:"1rem"}}>Secondary (Terms) </Typography>
          <FormControl sx={{ width: "100%", background: 'white' }}>
          <Select
            value={selectedTermSecondary}
            onChange={(e) => {setSelectedTermSecondary(e.target.value);dispatch(saveTermSecondary(e.target.value)) }}
            
            displayEmpty
            //disabled={true}
            label=""
            sx={{
            //   minHeight: 30,
              minWidth: 140,
              p: 1,
            }}
          >
            <MenuItem value="">
            Sélectionner une Terme
            </MenuItem>
        <MenuItem value={'1'}>Terme 1</MenuItem>
        <MenuItem value={'2'}>Terme 2</MenuItem>
        <MenuItem value={'3'}>Terme 3</MenuItem>
       
          </Select>
        </FormControl>



        </Grid>



        

        </Grid>


          <br/>
          <br/>




          <Grid xs={5} container style={{position:"relative",left:"6rem",display:"flex",justifyContent:"center",gap:"2rem"}}  spacing={4}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{marginBottom:"1rem"}}>Examens par trimestre </Typography>
          <FormControl sx={{ width: "100%", background: 'white' }}>
          <Select
            value={numberOfExamsPerTerm}
            onChange={(e) => {setNumberOfExamsPerTerm(e.target.value);dispatch(saveExamsPerTerm(e.target.value))} }
            displayEmpty
            label=""
            sx={{
            //   minHeight: 30,
              minWidth: 140,
              p: 1,
            }}
          >
            <MenuItem value="">
            Sélectionner une Numéro
            </MenuItem>
        <MenuItem value={'1'}>1</MenuItem>
        <MenuItem value={'2'}>2</MenuItem>
        <MenuItem value={'3'}>3</MenuItem>
       
          </Select>
        </FormControl>



         </Grid>

       
        </Grid>

          <br/>
          <br/>
         
        <Grid container style={{display:"flex",flexDirection:"column",justifyContent:"flex-start",marginLeft:"5%"}}  spacing={4}>
        <Typography variant="subtitle1" style={{position:"relative",left:"2rem",bottom:"-1rem"}}>Sujets </Typography>
        <Grid item xs={10.3}>
        
        {selectedClass &&  Number(selectedClass.slice(selectedClass.length-2,selectedClass.length)) <= 6 ?
         


         <Select
         style={{width:"100%",bakgroundColor:"white !important",height:"3rem",paddingLeft:"1rem"}}
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         value={selectedSubject}
         name={"Subject"}       
         
         onChange = {
           
           (e)=>{
            setSelectedSubject(e.target.value)
         
           if(!subjectSavingArr.includes(e.target.value)) {
             //setRadiologyIdArr([...radiologyIdArr,e.target.value])
           setSubjectSavingArr([...subjectSavingArr,e.target.value])
           ;dispatch(saveSubjectsForLevel([...subjectSavingArr,e.target.value]))
            }
         
         
         
         }
         
         
         }
         
         
         >
         <MenuItem value={"Géographie"}>{"Géographie"}</MenuItem>
         <MenuItem value={"Calcul Ecrit"}>{"Calcul Ecrit"}</MenuItem>
         <MenuItem value={"ECM"}>{"ECM"}</MenuItem>
         <MenuItem value={"Histoire"}>{"Histoire"}</MenuItem>
         <MenuItem value={"Sciences d'observation"}>{"Sciences d'observation"}</MenuItem>
         <MenuItem value={"Français Dictee"}>{"Français Dictee"}</MenuItem>
         <MenuItem value={"Français Questions -Redaction"}>{"Français Questions -Redaction"}</MenuItem>
         
         </Select>


         :


         selectedClass &&  Number(selectedClass.slice(selectedClass.length-2,selectedClass.length)) >= 7 && Number(selectedClass.slice(selectedClass.length-2,selectedClass.length)) <= 10 ?
         


         <Select
         style={{width:"100%",bakgroundColor:"white !important",height:"3rem",paddingLeft:"1rem"}}
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         value={selectedSubject}
         name={"Subject"}       
         
         onChange = {
           
           (e)=>{
            setSelectedSubject(e.target.value)
         
           if(!subjectSavingArr.includes(e.target.value)) {
             //setRadiologyIdArr([...radiologyIdArr,e.target.value])
           setSubjectSavingArr([...subjectSavingArr,e.target.value])
           ;dispatch(saveSubjectsForLevel([...subjectSavingArr,e.target.value]))
            }
         
         
         
         }
         
         
         }
         
         
         >
         <MenuItem value={"Français Dictée & Question"}>{"Français Dictée & Question"}</MenuItem>
         <MenuItem value={"Histoire"}>{"Histoire"}</MenuItem>
         <MenuItem value={"Français Redaction"}>{"Français Redaction"}</MenuItem>
        
         <MenuItem value={"Biologie"}>{"Biologie"}</MenuItem>
         <MenuItem value={"Chimie"}>{"Chimie"}</MenuItem>

         <MenuItem value={"ECM"}>{"ECM"}</MenuItem>
         <MenuItem value={"Géographie"}>{"Géographie"}</MenuItem>
         <MenuItem value={"Physique"}>{"Physique"}</MenuItem>
         <MenuItem value={"Mathématiques"}>{"Mathématiques"}</MenuItem>

         
         </Select>


         :



         <Select
         style={{width:"100%",bakgroundColor:"white !important",height:"3rem",paddingLeft:"1rem"}}
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         value={selectedSubject}
         name={"Subject"}       
         
         onChange = {
           
           (e)=>{
            setSelectedSubject(e.target.value)
         
           if(!subjectSavingArr.includes(e.target.value)) {
             //setRadiologyIdArr([...radiologyIdArr,e.target.value])
           setSubjectSavingArr([...subjectSavingArr,e.target.value])
           ;dispatch(saveSubjectsForLevel([...subjectSavingArr,e.target.value]))
            }
         
         
         
         }
         
         
         }
         
         
         >
         <MenuItem value={"Mathématiques"}>{"Mathématiques"}</MenuItem>
         <MenuItem value={"Philosophie"}>{"Philosophie"}</MenuItem>
         <MenuItem value={"Economie"}>{"Economie"}</MenuItem>
         <MenuItem value={"Géographie"}>{"Géographie"}</MenuItem>
         <MenuItem value={"Anglais"}>{"Anglais"}</MenuItem>

         <MenuItem value={"Histoire"}>{"Histoire"}</MenuItem>
         
         <MenuItem value={"Français"}>{"Français"}</MenuItem>
         <MenuItem value={"Chimie"}>{"Chimie"}</MenuItem>
         <MenuItem value={"Physique"}>{"Physique"}</MenuItem>
         <MenuItem value={"Biologie"}>{"Biologie"}</MenuItem>
         <MenuItem value={"Géologie"}>{"Géologie"}</MenuItem>

        
         
         </Select>



    
        }

      </Grid>

       </Grid>

        <br/>

    





   
        <Grid container style={{display:"flex",justifyContent:"flex-start",gap:"2rem",marginLeft:"5%"}}  spacing={4}>
       
        <Grid item xs={10.3}>
          {subjectSavingArr && subjectSavingArr.length >0 &&
               <div style={{padding: '10px', border: '1px solid #00000033',backgroundColor:"#FFFFFF",height:"8rem" }}>
                        <> 
                           &nbsp; 
                         {  subjectSavingArr.map((chipItem,index)=>(
                        <Chip label={chipItem} onClick={handleClick} onDelete={()=>{handleDeleteRad(chipItem,radiologyIdArr[index])}} />
                        ))
                          }
          
                        </>
               </div>
                        }
          </Grid>
       </Grid>

       

        <br/>

    


            <br/><br />
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
               <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
               <Button
                variant="contained"
                disabled={activeStep === 0 || loading}
                onClick={handleBack}
                style={{
                  display: activeStep === 0 ? 'none' : 'inline-block',
                  minWidth: '125px',
                  backgroundColor: 'transparent',
                  border: '1px solid #000000',
                  color: '#000000',
                  marginLeft: '4rem',
                  paddingTop: '15px',
                  paddingBottom: '15px',
                  paddingLeft: '20px',
                }}
              >
                Previous
              </Button>

              

              </Grid>
              <Box sx={{ flex: '1 1 auto' }} />
            </Box>
          </>
        )}
      </div>
      
    </Box>
  );
}
