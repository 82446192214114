//import FuseScrollbars from '@fuse/core/FuseScrollbars';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChatIcon from '@mui/icons-material/Chat';
import SendIcon from '@mui/icons-material/Send';

//import { selectContacts } from './store/contactsSlice';
//import { sendMessage } from './store/chatSlice';
import { sendChat } from 'src/redux/actions/chat.action';
import { addChatToParent,addAnnouncementToSchool } from 'src/redux/actions/chat.action';

const useStyles = makeStyles((theme) => ({
  messageRow: {
    '&.contact': {
      '& .bubble': {
        backgroundColor:'lightgray' /*theme.palette.background.paper*/,
        color: theme.palette.getContrastText(theme.palette.background.paper),
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        

        '& .time': {
          marginLeft: 12,
        },
      },
      '&.first-of-group': {
        '& .bubble': {
          borderTopLeftRadius: 20,
        },
      },
      '&.last-of-group': {
        '& .bubble': {
          borderBottomLeftRadius: 20,
        },
      },
    },
    '&.me': {
      paddingLeft: 40,
      

      '& .avatar': {
        order: 2,
        margin: '0 0 0 16px',
        display:'none'
        
      },
     '& .bubble': {
        marginLeft: 'auto',
        backgroundColor:'black',// theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        '& .time': {
          justifyContent: 'flex-end',
          right: 0,
          color:"black",
          marginRight: 12,
        },
      },
      '&.first-of-group': {
        '& .bubble': {
          borderTopRightRadius: 20,
        },
      },

      '&.last-of-group': {
        '& .bubble': {
          borderBottomRightRadius: 20,
        },
      },
    },
    '&.contact + .me, &.me + .contact': {
      paddingTop: 20,
      marginTop: 20,
    },
    '&.first-of-group': {
      '& .bubble': {
        borderTopLeftRadius: 20,
        paddingTop: 13,
      },
    },
    '&.last-of-group': {
      '& .bubble': {
        borderBottomLeftRadius: 20,
        paddingBottom: 13,
        '& .time': {
          display: 'flex',
        },
      },
    },
  },
}));

function ChatAnnouncement(props) {
  const dispatch = useDispatch();
  const contacts = /*useSelector(selectContacts)*/ [];
 // const selectedContactId = useSelector(({ chatApp }) => chatApp.contacts.selectedContactId);
  //const chat = useSelector(({ chatApp }) => chatApp.chat);
  // const user = useSelector(({ chatApp }) => chatApp.user);

  const classes = useStyles(props);
  const chatRef = useRef(null);
  const [messageText, setMessageText] = useState('');

 //New Hooks

//  const [connectStatus, setconnectStatus] = useState('');
 //const { isAuth, user } = useSelector((state) => state.login);
//const user = {uid:'yZIpS4WcZxZ150nesewtKL5U6w43' /*'m1aFbUYJCDhIFrFia4EbHVoALIk1'*/}

 const { selectedChatUser,selectedAnnouncement, chatMessages } = useSelector((state) => state.chat);
 const { connects } = useSelector((state) => state.user);
 const { user } = useSelector((state) => state.auth);
 let connectStatus = 'active';
 console.log(" user is --->",user.id)

  useEffect(() => {
    if (chatMessages) {
      scrollToBottom();
    }
  }, [chatMessages]);

  function scrollToBottom() {
    //chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }

  function shouldShowContactAvatar(item, i) {
    return (
      item.user1 === selectedChatUser.id 
      && ((chatMessages[i + 1] && chatMessages[i + 1].user1 !== selectedChatUser.id) || !chatMessages[i + 1])

      // item.who === selectedContactId &&
      // ((chat.dialog[i + 1] && chat.dialog[i + 1].who !== selectedContactId) || !chat.dialog[i + 1])
    );
  }

  function isFirstMessageOfGroup(item, i) {
    return i === 0 || (chatMessages[i - 1] && chatMessages[i - 1].user1 !== item.user1);
    // return i === 0 || (chat.dialog[i - 1] && chat.dialog[i - 1].who !== item.who);
  }

  function isLastMessageOfGroup(item, i) {
    return (
      i === chatMessages.length - 1 || (chatMessages[i + 1] && chatMessages[i + 1].user1 !== item.user1)
      // i === chat.dialog.length - 1 || (chat.dialog[i + 1] && chat.dialog[i + 1].who !== item.who)
    );
  }

  function onInputChange(ev) {
    setMessageText(ev.target.value);
  }

  function onMessageSubmit(ev) {
    ev.preventDefault();

  console.log("Message text--->",messageText)


    if (messageText === '') {
      return;
    }
   dispatch(
    //AGAINST sep 3 2024 - TRY AND REPRODUCE THIS IN THE PARENTS MODULE BUT USER 1 AND USER 2 WILL BE SWITCHED AROUND
     addAnnouncementToSchool({
       messageText,
       messageTitle:"Latest Announcement",
       sentBy:"admin"
     },user.schoolId)
   ).then(() => {
     setMessageText('');
   });
  }

 
  const testMe = () => {
    console.log('Connection Status is: ', connectStatus);
  }

  const connectsById = Object.fromEntries(
   connects.map(({ user2, type, status, invited_amt, skipped_amt }) => [user2, { type, status, invited_amt, skipped_amt }])
    );
    
      const chatMessagesOutput = chatMessages/*.map(({ user1, user2, messageText, time, isViewed, unread }) => ({
        user1, user2, messageText, time, isViewed, unread,
        ...(connectsById[user2] || { type: '', status: '', invited_amt: '', skipped_amt: ''})
      }));*/

  return (
    <div className={clsx('flex flex-col relative', props.className)}>

      {/*<FuseScrollbars ref={chatRef} className="flex flex-1 flex-col overflow-y-auto">*/}
        {selectedAnnouncement ? (
          <div className="flex flex-col pt-16 px-16 ltr:pl-56 rtl:pr-56 pb-40">
            
                <div
                  key={selectedAnnouncement.time}
                  className={clsx(
                    classes.messageRow,
                    'flex flex-col flex-grow-0 flex-shrink-0 items-start justify-end relative px-16 pb-4',
                    // { me: selectedAnnouncement.who === user.id },
                   
                    { me: selectedAnnouncement.sentBy === "admin" },
                    { contact: selectedAnnouncement.sentBy === "Parent"},
                    { 'first-of-group': isFirstMessageOfGroup(selectedAnnouncement) },
                    { 'last-of-group': isLastMessageOfGroup(selectedAnnouncement) },
                    /*i + 1 === chatMessages.length &&*/ 'pb-96'
                  )}
                >
                  {shouldShowContactAvatar(selectedAnnouncement) && (
                    <Avatar
                      className="avatar absolute ltr:left-0 rtl:right-0 m-0 -mx-32"
                      // src={contact.photoUrl}
                      src={selectedChatUser.profileImage}
                    />
                  )}
                  <div className="bubble flex relative items-center justify-center p-12 max-w-full shadow-none">
                    <div className="leading-tight whitespace-pre-wrap">{selectedAnnouncement.messageText}</div>
                    <div 
                      className="time absolute hidden w-full text-11 mt-8 -mb-24 ltr:left-0 rtl:right-0 bottom-0 whitespace-nowrap"
                      color="textSecondary"
                    > 
                     {/**THERE IS A BUG WHENEVER I UNCOMMENT THIS! */}
                      {/* formatDistanceToNow(new Date(selectedAnnouncement.time?selectedAnnouncement.time:''), { addSuffix: true })*/}
                    </div>
                  </div>
                </div>
              ;
         
          </div>
        ) : (
          <div className="flex flex-col flex-1">
            <div className="flex flex-col flex-1 items-center justify-center">
              {/*<Icon className="text-128" color="disabled">*/}
                <ChatIcon style={{position:"relative",top:"-1rem"}} className="text-64" color="disabled"/>
              {/*</Icon>*/}
            </div>
            <Typography className="px-16 pb-24 text-center" color="textSecondary" style={{color:"black"}}>
              Create A new Announcement by typing it below.
            </Typography>
          </div>
        )}
      {/*</FuseScrollbars>*/}

      
       {/* <button onClick={testMe()}>Click Me</button> */}
      {/*chatMessages && (
        <form onSubmit={onMessageSubmit} className="absolute bottom-0 right-0 left-0 py-16 px-8">
          <Paper style={{border:"1px solid lightgrey",boxShadow:"none"}} className="flex items-center relative rounded-24 ">
            <TextField
              autoFocus={false}
              disabled={connectStatus == 'pending' ? true : false}
              id="message-input"
              className="flex-1"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: 'flex flex-grow flex-shrink-0 mx-16 ltr:mr-48 rtl:ml-48 my-8',
                  input: '',
                },
                placeholder: 'Enter your message',
              }}
              InputLabelProps={{
                shrink: false,
                className: classes.bootstrapFormLabel,
              }}
              onChange={onInputChange}
              value={messageText}
            />
            <IconButton className="absolute ltr:right-0 rtl:left-0 top-0" type="submit">
             
                <SendIcon className="text-24" color="action"/>
            
            </IconButton>
          </Paper>
        </form>
      )*/}
    </div>
  );
}

export default ChatAnnouncement;
