import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@mui/material';
import TextField from '@material-ui/core/TextField';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createTeacher, uploadDocImages } from 'src/redux/actions/student.action';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '4rem',
    paddingRight: '4rem',
  },
  searchInput: {
    background: 'white',
    border: '1px solid #00000026',
    padding: '10px',
    borderRadius: '8px',
    // marginRight: theme.spacing(2),
    width: '100%',
    minWidth: '100%',
    '& .MuiInputBase-input': {
      color: 'grey',
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'grey',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'grey',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'grey',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey',
    },
  },
  searchButton: {
    color: '#fff',
    padding: '15px',
    minWidth: '45%',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const AddTeacher = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

 


  const [teacherPassportFile, setTeacherPassportFile] = useState({ selectedFile: [], selectedFileName: [] });
  const {themeColor} = useSelector(  (state) => state.settings)
  const {user} = useSelector(  (state) => state.auth)

  console.log("currentUser is--->",user)


  const [state, setState] = useState({
    fname: '',
    lname: '',
    dob: '',
    email: '',
    gender: '',
    phone:'',
    registrationId: '',
    studentshipType: '',
    class: '',
    section: '',
    schoolId:user.schoolId
  });

  const handleTeacherPassportFile = (event) => {
    setTeacherPassportFile({
      selectedFile: event.target.files[0],
      selectedFileName: event.target.files[0].name,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const files = [teacherPassportFile.selectedFile];

    try {
      setLoading(true);
      const urls = await Promise.all(files.map((file) => dispatch(uploadDocImages(file))));

      const [teacherPassportFileUrl] = urls;

      const teacherData = { state, teacherPassportFileUrl };
      dispatch(createTeacher(teacherData, navigate, setLoading));
    } catch (error) {
      console.error('Error uploading images: ', error);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Prénom</Typography>
          <TextField
            name="fname"
            placeholder="Prénom"
            fullWidth
            value={state.fname}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Nom de famille</Typography>
          <TextField
            name="lname"
            placeholder="Nom de famille"
            fullWidth
            value={state.lname}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Date de Naissance</Typography>
          <TextField
            type="date"
            name="dob"
            placeholder="01/01/1999"
            fullWidth
            value={state.dob}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Gender</Typography>
          <select
            name="gender"
            value={state.gender}
            onChange={handleChange}
            className={classes.searchInput}
            style={{ minHeight: '50px', fontSize: '17px', outline: '1px solid #eee' }}
            required
          >
            <option value="">Sélectionnez le sexe</option>
            <option value="Mâle">Mâle</option>
            <option value="Femelle">Femelle</option>
          </select>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle1">Numéro de téléphone</Typography>
          <TextField
           
            name="phone"
            placeholder="numéro de téléphone"
            fullWidth
            value={state.phone}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>


        <Grid item xs={6}>
          <Typography variant="subtitle1">ID d'enregistrement</Typography>
          <TextField
            name="registrationId"
            type="number"
            placeholder="00037278488"
            fullWidth
            value={state.registrationId}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>
        {/*<Grid item xs={6}>
          <Typography variant="subtitle1">Studentship Type</Typography>
          <select
            name="studentshipType"
            value={state.studentshipType}
            onChange={handleChange}
            className={classes.searchInput}
            style={{ minHeight: '50px', fontSize: '17px', outline: '1px solid #eee' }}
            required
          >
            <option value=""></option>
            <option value="Mâle">Boarding</option>
            <option value="Femelle">Day</option>
          </select>
          </Grid>*/}
        <Grid item xs={6}>
          <Typography variant="subtitle1">Class</Typography>
          <select
            name="class"
            value={state.class}
            onChange={handleChange}
            className={classes.searchInput}
            style={{ minHeight: '50px', fontSize: '17px', outline: '1px solid #eee' }}
            required
          >
           <option value="">Select Class</option>
            <option value="Niveau 1">Niveau 1</option>
            <option value="Niveau 2">Niveau 2</option>
            <option value="Niveau 3">Niveau 3</option>
            <option value="Niveau 4">Niveau 4</option>
            <option value="Niveau 5">Niveau 5</option>
            <option value="Niveau 6">Niveau 6</option>
            <option value="Niveau 7">Niveau 7</option>
            <option value="Niveau 8">Niveau 8</option>
            <option value="Niveau 9">Niveau 9</option>
            <option value="Niveau 10">Niveau 10</option>
            <option value="Niveau 11">Niveau 11</option>
            <option value="Niveau 12">Niveau 12</option>
            <option value="Niveau 13">Niveau 13</option>
          </select>
        </Grid>
        {/*<Grid item xs={6}>
          <Typography variant="subtitle1">Section</Typography>
          <select
            name="section"
            value={state.section}
            onChange={handleChange}
            className={classes.searchInput}
            style={{ minHeight: '50px', fontSize: '17px', outline: '1px solid #eee' }}
            required
          >
            <option value=""></option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
          </select>
        </Grid>*/}
        <Grid item xs={6}>
          <Typography variant="subtitle1">Adresse e-mail</Typography>
          <TextField
            name="email"
            placeholder="Enter email"
            type="email"
            fullWidth
            value={state.email}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>

        <Grid item container xs={6} direction="row" alignItems="center">
          <Grid item xs={8}>
            <Typography variant="subtitle1">Teacher Passport (Format: PNG, JPEG, JPG)</Typography>
            <TextField
              placeholder="sélectionner un fichier"
              disabled
              fullWidth
              value={teacherPassportFile.selectedFileName}
              onChange={handleChange}
              className={classes.searchInput}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              component="label"
              variant="contained"
              style={{
                minHeight: '52px',
                minWidth: '145px',
                backgroundColor: '#000000',
                marginTop: '20px',
              }}
            >
              <b>Choisir</b>
              <input type="file" style={{ display: 'none' }} onChange={handleTeacherPassportFile} />
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}></Grid>
        <Button
          disabled={loading}
          variant="contained"
          onClick={handleSubmit}
          style={{
            minWidth: '125px',
            backgroundColor: /*'#762AD7'*/themeColor,
            paddingTop: '15px',
            paddingBottom: '15px',
            paddingLeft: '20px',
            marginLeft: '30px',
          }}
        >
          {loading ? 'Chargement...' : 'Sauvegarder'}
        </Button>
      </Grid>
    </div>
  );
};

export default AddTeacher;
