import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography, Button, Select, MenuItem } from '@mui/material';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '4rem',
    paddingRight: '4rem',
  },
  searchInput: {
    background: 'white',
    border: '1px solid #00000026',
    padding: '10px',
    borderRadius: '8px',
    // marginRight: theme.spacing(2),
    width: '100%',
    minWidth: '100%',
    '& .MuiInputBase-input': {
      color: 'grey',
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'grey',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'grey',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'grey',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey',
    },
  },
  searchButton: {
    color: '#fff',
    padding: '15px',
    minWidth: '45%',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const Step1 = ({state, handleChange}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Prénom</Typography>
          <TextField
            name="fname"
            placeholder="Prénom"
            fullWidth
            value={state.fname}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Nom de famille</Typography>
          <TextField
            name="lname"
            placeholder="Nom de famille"
            fullWidth
            value={state.lname}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Date de Naissance</Typography>
          <TextField
            type="date"
            name="dob"
            placeholder="01/01/1999"
            fullWidth
            value={state.dob}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Gender</Typography>
          <select
            name="gender"
            value={state.gender}
            onChange={handleChange}
            className={classes.searchInput}
            style={{ minHeight: '50px', fontSize: '17px', outline: '1px solid #eee' }}
            required
          >
            <option value="">Sélectionnez le sexe</option>
            <option value="Mâle">Mâle</option>
            <option value="Femelle">Femelle</option>
          </select>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">ID d'enregistrement</Typography>
          <TextField
            name="registrationId"
            type="number"
            placeholder="00037278488"
            fullWidth
            value={state.registrationId}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>
       {/* <Grid item xs={6}>
          <Typography variant="subtitle1">Studentship Type</Typography>
          <select
            name="studentshipType"
            value={state.studentshipType}
            onChange={handleChange}
            className={classes.searchInput}
            style={{ minHeight: '50px', fontSize: '17px', outline: '1px solid #eee' }}
            required
          >
            <option value=""></option>
            <option value="Mâle">Boarding</option>
            <option value="Femelle">Day</option>
          </select>
          </Grid>*/}
        <Grid item xs={6}>
          <Typography variant="subtitle1">Classe</Typography>
          <select
            name="class"
            value={state.class}
            onChange={handleChange}
            className={classes.searchInput}
            style={{ minHeight: '50px', fontSize: '17px', outline: '1px solid #eee' }}
            required
          >
            <option value="">Sélectionner une classe</option>
            <option value="Niveau 1">Niveau 1</option>
            <option value="Niveau 2">Niveau 2</option>
            <option value="Niveau 3">Niveau 3</option>
            <option value="Niveau 4">Niveau 4</option>
            <option value="Niveau 5">Niveau 5</option>
            <option value="Niveau 6">Niveau 6</option>
            <option value="Niveau 7">Niveau 7</option>
            <option value="Niveau 8">Niveau 8</option>
            <option value="Niveau 9">Niveau 9</option>
           <option value="Niveau 10">Niveau 10</option>
           <option value="Niveau 11">Niveau 11</option>
           <option value="Niveau 12">Niveau 12</option>
           <option value="Niveau 13">Niveau 13</option>
          </select>
        </Grid>
       <Grid item xs={6}>
          <Typography variant="subtitle1">Section</Typography>
          <select
            name="section"
            value={state.section}
            onChange={handleChange}
            className={classes.searchInput}
            style={{ minHeight: '50px', fontSize: '17px', outline: '1px solid #eee' }}
            required
          >
            <option value=""></option>
            <option value="Elementary">Elementary</option>
            <option value="Secondary">Secondary</option>
           
          </select>
        </Grid>
        
        {/*<Grid item xs={6}>
          <Typography variant="subtitle1">Gardien Name (if any)</Typography>
          <TextField
            name="guardianName"
            placeholder="Enter name"
            fullWidth
            value={state.guardianName}
            onChange={handleChange}
            className={classes.searchInput}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>*/}
      </Grid>
    </div>
  );
};

export default Step1;
