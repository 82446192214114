import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography, Grid, Button } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import Searchbar from './Searchbar2';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import Searchbar2 from './Searchbar2';
import { useSelector } from 'react-redux';
import CustomSearchBar from 'src/components/global/CustomSearchBar';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  backgroundColor: 'white !important',
  boxShadow: 'none !important',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px) !important`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  //minHeight: HEADER_MOBILE,
  height:"7rem !important",
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    //padding: theme.spacing(0, 5),
    paddingTop:"1rem !important",
    paddingBottom:"1rem !important",
    paddingLeft:"1rem !important",
    paddingRight:"2rem !important",
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const { user,school } = useSelector((state) => state.auth);
  return (
    <StyledRoot>
      <StyledToolbar>
        {/*<IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>*/}
        <Typography variant="h4" sx={{color: '#000000', fontSize: '24px !important' }}>
       <b> {school && school.name ?school.name:school && school.schoolName?school.schoolName:" " }</b>
         {/* Welcome {user?.firstName + " " + user?.lastName}🖐🏽 */}
        </Typography>

        <Box sx={{ flexGrow: 1 }} />
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%' }}>
        <CustomSearchBar title="Chercher n'importe quoi..."/>
      </Box>
      &nbsp; &nbsp;
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          style={{ minHeight: '50px', minWidth: '145px', backgroundColor: '#000000',borderRadius:"0.3rem" }}
        >
          CHERCHER
        </Button>
      </Box>
    </Grid>
        {/* <Searchbar /> */}
        {/* <Searchbar2 /> */}
        <Box sx={{ flexGrow: 1 }} />
        <Typography variant="h6" sx={{color: '#000000', fontSize: '16px' }}>
        Administrateur de l'école &nbsp;
        </Typography>
        
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack> 


      </StyledToolbar>
    </StyledRoot>
  );
}
