// component
import SvgColor from '../../../components/svg-color';

// const icon = (name) => <SvgColor src={`/assets/icons/${name}.png`} sx={{ width: 1, height: 1 }} />;
const icon = (name) => <img src={`/assets/icons2/${name}.png`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/home',
    icon: icon('dashboard'),
  },
  {
    title: 'étudiant',
    path: '/dashboard/students',
    icon: icon('student'),
  },
  {
    title: 'professeur',
    path: '/dashboard/teachers',
    icon: icon('teacher'),
  },

  {
    title: 'rapports',
    path: '/dashboard/reports',
    icon: icon('report'),
  },

  {
    title: 'messages',
    path: '/dashboard/messages',
    icon: icon('dashboard'),
  
  },
  {
    title: 'paramètres',
     path: '/dashboard/settings',
   // path: '#',
    icon: icon('settings'),
  },
  {
    title: 'Se déconnecter',
     path: '/logout',
    //path: '#',
    icon: icon('logout'),
  },
];

export default navConfig;
