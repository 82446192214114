import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, Table, TableHead, TableBody, TableRow, TableCell, Checkbox } from '@mui/material';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createStudentResult, updateStudentAttendance } from 'src/redux/actions/student.action';
import { saveThemeColor, saveThemeImage } from 'src/redux/reducers/settings.slice';

const subjects = ['Mathematics', 'English', 'Biology', 'Physics', 'Home Keeping', 'Economics', 'Fine arts', 'French'];

const gradeRanges = [
  { range: [76, 100], grade: 'A' },
  { range: [70, 75], grade: 'B' },
  { range: [60, 69], grade: 'C' },
  { range: [55, 59], grade: 'D' },
  { range: [45, 54], grade: 'E' },
  { range: [0, 44], grade: 'F' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '4rem',
    paddingRight: '4rem',
  },
  searchInput: {
    background: 'white',
    border: '1px solid black',
    padding: '10px',
    marginLeft: '10px',
    // justifyContent: 'center',
    borderRadius: '1px',
    width: '22%',
    height: '10%',
    '& .MuiInputBase-input': {
      color: 'grey',
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'grey',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'grey',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'grey',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey',
    },
  },
}));

const AttendanceReportForm = ({studentData}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [scores, setScores] = useState({});
  const [cummulative, setCumulative] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dupStudentData,setDupStudentData] = useState(studentData.attendance)



  const { themeColor } = useSelector((state) => state.settings);
  const {school } = useSelector((state) => state.auth);

  console.log("attendance for this student is --->",studentData.attendance)


  useEffect(()=>{

 if(!themeColor){
 dispatch(saveThemeColor( school && school.settings &&  school.settings.themeColor))
 dispatch(saveThemeImage(school && school.settings &&  school.settings.themeImage))
 }


  },[])



  const calculateFinalGrade = (totalScore) => {
    for (const range of gradeRanges) {
      if (totalScore >= range.range[0] && totalScore <= range.range[1]) {
        return range.grade;
      }
    }
    return '';
  };

  const handleScoreChange = (subject, scoreType, value) => {
    const updatedScores = {
      ...scores,
      [subject]: {
        ...scores[subject],
        [scoreType]: value,
      },
    };
       // Calculate and set the final grade based on entered scores
       if (scoreType === 'ca' || scoreType === 'testScores' || scoreType === 'examScores') {
        const ca = parseFloat(updatedScores[subject]?.ca || 0);
        const testScores = parseFloat(updatedScores[subject]?.testScores || 0);
        const examScores = parseFloat(updatedScores[subject]?.examScores || 0);
  
        const totalScore = ca + testScores + examScores;
        const finalGrade = calculateFinalGrade(totalScore);
        updatedScores[subject].finalGrade = finalGrade;
      }
  
      setScores(updatedScores);
    };

    const handleEnterClick = () => {
      // Calculate cumulative and console.log all values
      let totalCumulative = 0;
      for (const subject of subjects) {
        const ca = parseFloat(scores[subject]?.ca || 0);
        const testScores = parseFloat(scores[subject]?.testScores || 0);
        const examScores = parseFloat(scores[subject]?.examScores || 0);
  
        const totalScore = ca + testScores + examScores;
        const finalGrade = calculateFinalGrade(totalScore);
        scores[subject].finalGrade = finalGrade;
  
        totalCumulative += totalScore;
      }
      setCumulative(totalCumulative);
      console.log('Scores:', scores);
      console.log('Cumulative:', totalCumulative);

      const studentId = studentData.studentId;
      const teacherId = user.teacherId;
      const finalGrade = 'A';
      const data ={ scores, totalCumulative, finalGrade, studentId, teacherId };
      dispatch(createStudentResult(data, navigate, setLoading));
    };

  return (
    <>
      <Table style={{background: '#F8F8F8'}}>
        <TableHead>
          <TableRow>
            <TableCell>Week #</TableCell>
            
            <TableCell style={{textAlign:"center"}}><div style={{position:"relative",left:"-60px"}}>Monday</div></TableCell>
            <TableCell>Tuesday</TableCell>
            <TableCell>Wednesday</TableCell>
            <TableCell>Thursday</TableCell>
            <TableCell>Friday</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dupStudentData ? dupStudentData.map((item, index) => (
            <TableRow key={item.id}>
              <TableCell>
                <div style={{display:"flex",alignItems:"center"}}>
                <Checkbox />{' '}{item.week}
                </div>
              </TableCell>
              
            
             
              {
                <TableCell>
                <TextField
                  value={item.mon === true ?'Y'  :item.mon === false ? 'N':''}
                  onChange={(e) =>{
                   
                      dupStudentData[index].mon = e.target.value
                      setDupStudentData(  [...dupStudentData])
                      
                   
                    if(e.target.value === 'Y' || e.target.value === 'y' ){
                      dupStudentData[index].mon = true
                     setDupStudentData(  [...dupStudentData]
                     )
                     dispatch(updateStudentAttendance(studentData.id,dupStudentData))
                    }
                    else if(e.target.value === 'N' || e.target.value === 'n' ){
                      dupStudentData[index].mon = false
                      setDupStudentData(  [...dupStudentData]
                       )
                       dispatch(updateStudentAttendance(studentData.id,dupStudentData))
                    }
                    
                    }}
                  multiline
                  rows={1}
                  style={{ marginLeft: '40px' }}
                  className={classes.searchInput}
                  InputProps={{
                    disableUnderline: true,
                    style:{
                      height:"1.1rem"
                    }
                  }}
                />
              </TableCell>
              }
              
              <TableCell>
                <TextField
                 value={item.tue === true ?'Y'  :item.tue === false ? 'N':''}
                  //onChange={(e) => handleScoreChange(subject, 'testScores', e.target.value)}
                  onChange={(e) =>{
                   
                      dupStudentData[index].tue = e.target.value
                      setDupStudentData(  [...dupStudentData])
                   
                    if(e.target.value === 'Y' || e.target.value === 'y' ){
                      dupStudentData[index].tue = true
                     setDupStudentData(  [...dupStudentData]
                     )
                     dispatch(updateStudentAttendance(studentData.id,dupStudentData))
                    }
                    else if(e.target.value === 'N' || e.target.value === 'n' ){
                      dupStudentData[index].tue = false
                      setDupStudentData(  [...dupStudentData]
                       )
                       dispatch(updateStudentAttendance(studentData.id,dupStudentData))
                    }
                    
                    }}
                  multiline
                  rows={1}
                  className={classes.searchInput}
                  InputProps={{
                    disableUnderline: true,
                    style:{
                      height:"1.1rem"
                    }
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={item.wed=== true ?'Y'  :item.wed=== false ? 'N':''}
                  onChange={(e) =>{
                    
                      dupStudentData[index].wed = e.target.value
                      setDupStudentData(  [...dupStudentData])
                   
                    if(e.target.value === 'Y' || e.target.value === 'y' ){
                      dupStudentData[index].wed = true
                     setDupStudentData(  [...dupStudentData]
                     )
                     dispatch(updateStudentAttendance(studentData.id,dupStudentData))
                    }
                    else if(e.target.value === 'N' || e.target.value === 'n' ){
                      dupStudentData[index].wed = false
                      setDupStudentData(  [...dupStudentData]
                       )
                       dispatch(updateStudentAttendance(studentData.id,dupStudentData))
                    }
                    
                    }}
                  multiline
                  rows={1}
                  className={classes.searchInput}
                  InputProps={{
                    disableUnderline: true,
                    style:{
                      height:"1.1rem"
                    }
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={item.thu === true ?'Y'  :item.thu === false ? 'N':''}
                  
                  
                  onChange={(e) =>{
                   
                      dupStudentData[index].thu = e.target.value
                      setDupStudentData(  [...dupStudentData])
                   
                    if(e.target.value === 'Y' || e.target.value === 'y' ){
                      dupStudentData[index].thu = true
                     setDupStudentData(  [...dupStudentData]
                     )
                     dispatch(updateStudentAttendance(studentData.id,dupStudentData))
                    }
                    else if(e.target.value === 'N' || e.target.value === 'n' ){
                      dupStudentData[index].thu = false
                      setDupStudentData(  [...dupStudentData]
                       )
                       dispatch(updateStudentAttendance(studentData.id,dupStudentData))
                    }
                    
                    }}
                  multiline
                  rows={1}
                  className={classes.searchInput}
                  InputProps={{
                    disableUnderline: true,
                    style:{
                      height:"1.1rem"
                    }
                  }}
                />
              </TableCell>


              <TableCell>
                <TextField
                  value={item.fri === true ?'Y'  :item.fri === false ? 'N':''}
                  onChange={(e) =>{
                   
                      dupStudentData[index].fri = e.target.value
                      setDupStudentData(  [...dupStudentData])
                   
                    if(e.target.value === 'Y' || e.target.value === 'y' ){
                      dupStudentData[index].fri = true
                     setDupStudentData(  [...dupStudentData]
                     )
                     dispatch(updateStudentAttendance(studentData.id,dupStudentData))
                    }
                    else if(e.target.value === 'N' || e.target.value === 'n' ){
                      dupStudentData[index].fri = false
                      setDupStudentData(  [...dupStudentData]
                       )
                       dispatch(updateStudentAttendance(studentData.id,dupStudentData))
                    }
                    
                    }}
                  multiline
                  rows={1}
                  className={classes.searchInput}
                  InputProps={{
                    disableUnderline: true,
                    style:{
                      height:"1.1rem"
                    }
                  }}
                />
              </TableCell>

            </TableRow>
          ))
        :
        
        <TableRow>
        <TableCell colSpan={6}>aucune fréquentation enregistrée pour l'instant.</TableCell>
      </TableRow>
        
        }
        </TableBody>
      </Table>
      <br />
      <br />

      {/*
      <Grid container style={{ backgroundColor: '#F9F9F9', padding: '20px', borderRadius: '14px' }}>
      <Grid item xs={6}>
        <Typography variant="body1" align="left" style={{color: themeColor?themeColor:' #D72A34', fontSize: '20px'}}>
        <b><span style={{lineHeight: '3rem'}}>Cumulatif:</span></b>       
          <TextField
          type='number'
                  value={cummulative}
                  onChange={(e) => setCumulative(e.target.value)}
                  multiline
                  rows={1}
                  style={{maxWidth: '12%', height: '50px'}}
                  className={classes.searchInput}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
          <TextField
          type='number'
                  value={cummulative}
                  onChange={(e) => setCumulative(e.target.value)}
                  multiline
                  rows={1}
                  style={{maxWidth: '20%', height: '50px'}}
                  className={classes.searchInput}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
        </Typography>
      </Grid>
      <Grid item xs={6}>
          <Typography variant="body1" align="right" style={{ color: '#2AD776', fontSize: '20px' }}>
            <Button
              variant="contained"
              style={{ minHeight: '50px', minWidth: '145px', backgroundColor: themeColor?themeColor:' #D72A34' }}
              onClick={handleEnterClick}
              disabled={loading}
            >
              {loading ? "Loading" : "Entrer"}
            </Button>
          </Typography>
        </Grid>
    </Grid>
                */}

    </>
  );
};

export default AttendanceReportForm;
