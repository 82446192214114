import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography, FormControl, Box, Select, MenuItem, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyGroups } from 'src/redux/actions/group.action';
import { fetchUserData } from 'src/redux/actions/auth.action';

import merge from 'lodash/merge';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { fetchMyTransactions } from 'src/redux/actions/transaction.action';
import CustomToggleSwitch from 'src/components/buttons/CustomToogleSwitch';
import CustomSearchBar from 'src/components/global/CustomSearchBar';
import SearchIcon from '@mui/icons-material/Search';
import ViewStudents from 'src/components/students/ViewStudents';
import AddStudent from 'src/components/students/AddStudent';
import { getStudents } from 'src/redux/actions/student.action';



export default function StudentPage() {
  const theme = useTheme();
    
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { myGroups, isLoading } = useSelector((state) => state.group);
  const { students } = useSelector((state) => state.student);

  const [levelFilter,setLevelFilter] = useState(false)
  const [genderFilter,setGenderFilter] = useState(false)
  const [statusFilter,setStatusFilter] = useState(false)

  const [studentsToDisplay,setStudentsToDisplay] = useState([...students])

  const [mainMenuOpen,setMainMenuOpen] = useState(false)


  

  useEffect(() => {
    dispatch(getStudents());
    dispatch(fetchUserData(user?.id));
  }, [])



  const [selectedOption, setSelectedOption] = useState('');
  const [selectedValue, setSelectedValue] = useState('');

  const [selectedLevel, setSelectedLevel] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  const [activeButton, setActiveButton] = useState('viewStudents');

  useEffect(() => {
    /*FILTERING BY LEVEL */
         if(selectedLevel){
    
    
       let studentsFilteredByLevel = students.filter((item)=> (item.class && Number(item.class.slice(item.class.length-2,item.class.length)) ===  Number(selectedLevel.slice(selectedLevel.length-2,selectedLevel.length)) ))
      
        console.log("selected level last 2 digits--->",Number(selectedLevel.slice(selectedLevel.length-2,selectedLevel.length)))
    
       setStudentsToDisplay(studentsFilteredByLevel )
    
         }
    
    /*FILTERING BY GENDER */ 
         if(selectedGender){
    
    
          let studentsFilteredByGender = students.filter((item)=> (item.gender && item.gender===  selectedGender ))
         
           console.log("students filtered by level--->",studentsFilteredByGender)
       
          setStudentsToDisplay(studentsFilteredByGender)
       
            }
    
    
       /*FILTERING BY PAYMENT STATUS */   
       
       if(selectedStatus){
    
    
        let studentsFilteredByStatus = students.filter((item)=> (item.paymentStatus && item.paymentStatus ===  selectedStatus))
       
         console.log("students filtered by lpayment status--->",studentsFilteredByStatus)
     
        setStudentsToDisplay(studentsFilteredByStatus)
     
          }
    
    
        
    
        
      }, [selectedLevel,selectedGender,selectedStatus])



  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setSelectedValue(event.target.value);
  };



  const handleSelectLevelChange = (event) => {
    setSelectedLevel(event.target.value);
   
  };

  const handleSelectGenderChange = (event) => {
    setSelectedGender(event.target.value);
   
  };


  const handleSelectStatusChange = (event) => {
    setSelectedStatus(event.target.value);
   
  };

  const handleSelectChange1 = (event) => {
  
    setSelectedValue(event.target.value);
  };

  const handleViewStudentsClick = () => {
    setActiveButton('viewStudents');
 
  };

  const handleAddStudentsClick = () => {
    setActiveButton('addStudents');
  };

  return (
    <>

      <Container maxWidth="xl">
        <Grid container spacing={2} alignItems="center">
     <CustomToggleSwitch activeButton={activeButton} setActiveButton={setActiveButton} handleViewStudentsClick={handleViewStudentsClick} handleAddStudentsClick={handleAddStudentsClick}/>
     <Grid item sx={{mb: 2}}>
     <FormControl sx={{ minWidth: 140 }}>
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            displayEmpty
            label=""
            sx={{
            //   minHeight: 30,
              minWidth: 140,
              p: 1,
            }}
          >
            <MenuItem value="">
            Sélectionnez une classe
            </MenuItem>
            <MenuItem value={'Level 1'}>Niveau 1</MenuItem>
        <MenuItem value={'Level 2'}>Niveau 2</MenuItem>
        <MenuItem value={'Level 3'}>Niveau 3</MenuItem>
        <MenuItem value={'Level 4'}>Niveau 4</MenuItem>
        <MenuItem value={'Level 5'}>Niveau 5</MenuItem>
        <MenuItem value={'Level 7'}>Niveau 7</MenuItem>
        <MenuItem value={'Level 8'}>Niveau 8</MenuItem>
        <MenuItem value={'Level 9'}>Niveau 9</MenuItem>
        <MenuItem value={'Level 10'}>Niveau 10</MenuItem>
        <MenuItem value={'Level 11'}>Niveau 11</MenuItem>
        <MenuItem value={'Level 12'}>Niveau 12</MenuItem>
        <MenuItem value={'Level 13'}>Niveau 13</MenuItem>
          </Select>
        </FormControl>
      </Grid>
     <Grid item sx={{mb: 2}}>
     {/*<FormControl sx={{ minWidth: 140 }}>
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            displayEmpty
            label=""
            sx={{
            //   minHeight: 30,
              minWidth: 140,
              p: 1,
            }}
          >
            <MenuItem value="">
              Select Section
            </MenuItem>
            <MenuItem value={1}>Option 1</MenuItem>
            <MenuItem value={2}>Option 2</MenuItem>
            <MenuItem value={3}>Option 3</MenuItem>
          </Select>
        </FormControl>*/}
      </Grid>
      &nbsp; &nbsp;
      <Box sx={{ width: '20%' }}>
        <CustomSearchBar  title={"Recherche étudiant"} />
      </Box>
      <Box sx={{ flexGrow: 1}}>
        <Button
          variant="contained"
          style={{ minHeight: '50px', minWidth: '45px', backgroundColor: '#000000' }}
        >
          <SearchIcon />
        </Button>
      </Box>

      <Grid item sx={{mb: 2,position:"relative",width:"9rem"}}>
     <FormControl sx={{ minWidth: 140, position:"absolute",left:"0rem",top:"0rem" }}>
          <Select
            value={selectedValue}
            onChange={handleSelectChange}
            displayEmpty
            label=""
            sx={{
            //   minHeight: 30,
              minWidth: 120,
              p: 1,
            }}
          >
          
          
           {/* <p  onMouseLeave={()=>{setMainMenuOpen(false)}}>  */}
            <MenuItem value="">
              Filtrer par
            </MenuItem>
            <MenuItem onClick={(e)=>{setLevelFilter(true);setGenderFilter(false); setStatusFilter(false);setMainMenuOpen(true);setSelectedValue(e.target.value)}} value={"Niveau"}>Niveau</MenuItem>
            <MenuItem onClick={(e)=>{setLevelFilter(false);setGenderFilter(true); setStatusFilter(false);setMainMenuOpen(true);setSelectedValue(e.target.value)}} value={"Gender"}>Gender</MenuItem>
            <MenuItem onClick={(e)=>{setLevelFilter(false);setGenderFilter(false); setStatusFilter(true);setMainMenuOpen(true);setSelectedValue(e.target.value)}} value={"Status"}>Status</MenuItem>

            <MenuItem style={{display:"none"}} value={'Level 1'}>Niveau 1</MenuItem>
        <MenuItem style={{display:"none"}} value={'Level 2'}>Niveau 2</MenuItem>
        <MenuItem style={{display:"none"}} value={'Level 3'}>Niveau 3</MenuItem>
        <MenuItem style={{display:"none"}} value={'Level 4'}>Niveau 4</MenuItem>
        <MenuItem style={{display:"none"}} value={'Level 5'}>Niveau 5</MenuItem>
        <MenuItem style={{display:"none"}} value={'Level 7'}>Niveau 7</MenuItem>
        <MenuItem style={{display:"none"}} value={'Level 8'}>Niveau 8</MenuItem>
        <MenuItem style={{display:"none"}} value={'Level 9'}>Niveau 9</MenuItem>
        <MenuItem style={{display:"none"}} value={'Level 10'}>Niveau 10</MenuItem>
        <MenuItem style={{display:"none"}} value={'Level 11'}>Niveau 11</MenuItem>
        <MenuItem style={{display:"none"}} value={'Level 12'}>Niveau 12</MenuItem>
        <MenuItem style={{display:"none"}} value={'Level 13'}>Niveau 13</MenuItem>

        <MenuItem style={{display:"none"}}   value={"Mâle"}>Mâle</MenuItem>
        <MenuItem style={{display:"none"}}   value={"Femelle"}>Femelle</MenuItem>


        <MenuItem style={{display:"none"}}  value={"Payé"}>Payé</MenuItem>
        <MenuItem style={{display:"none"}}  value={"Non payé"}>Non payé</MenuItem>

           {/*</p>*/}

          </Select>
        </FormControl>


         {/*1 LEVEL FILTER DROPDOWN */}
         { levelFilter &&
        <div style={{  position:"absolute",left:"1rem",top:"4rem",backgroundColor:"white"}}>
        <FormControl style={{ width: 120}}>
          <Select onMouseLeave={()=>{setLevelFilter(false)}}
            value={selectedLevel}
            onChange={handleSelectLevelChange}
            displayEmpty
            label=""
            sx={{
            //   minHeight: 30,
              minWidth: 120,
              p: 1,
            }}
          >
            <MenuItem onClick={()=>{setGenderFilter(false);setMainMenuOpen(false)}} value={false}>effacer le filtre</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 1"}>Niveau 1</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 2"}>Niveau 2</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 3"}>Niveau 3</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 4"}>Niveau 4</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 5"}>Niveau 5</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 6"}>Niveau 6</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 7"}>Niveau 7</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 8"}>Niveau 8</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 9"}>Niveau 9</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 10"}>Niveau 10</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 11"}>Niveau 11</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 12"}>Niveau 12</MenuItem>
            <MenuItem onClick={()=>{setLevelFilter(false);setMainMenuOpen(false)}} value={"Niveau 13"}>Niveau 13</MenuItem>

            
           
          </Select>
        </FormControl>
        </div>
         }



                           {/*2 GENDER FILTER DROPDOWN */}
                             { genderFilter &&
                                  <div style={{  position:"absolute",left:"1rem",top:"4rem",backgroundColor:"white"}} >
                                     <FormControl sx={{ width: 120 }}>
                                       <Select  onMouseLeave={()=>{setGenderFilter(false)}}
                                         value={selectedGender}
                                         onChange={handleSelectGenderChange}
                                         displayEmpty
                                         label=""
                                         sx={{
                                         //   minHeight: 30,
                                           minWidth: 120,
                                           p: 1,
                                         }}
                                       >
                                        
                                        <MenuItem onClick={()=>{setGenderFilter(false);setMainMenuOpen(false)}} value={false}>effacer le filtre</MenuItem>
                                         <MenuItem  onClick={()=>{setGenderFilter(false);setMainMenuOpen(false)}} value={"Mâle"}>Mâle</MenuItem>
                                         <MenuItem  onClick={()=>{setGenderFilter(false);setMainMenuOpen(false)}} value={"Femelle"}>Femelle</MenuItem>
                                        
                                       </Select>
                                     </FormControl>
                                 </div>
                                      }
                             


                      {/*3 STATUS FILTER DROPDOWN */}
                     { statusFilter &&
                         <div style={{  position:"absolute",left:"1rem",top:"4rem",backgroundColor:"white"}} >
                             <FormControl sx={{ minWidth: 140}}>
                               <Select  onMouseLeave={()=>{setStatusFilter(false)}}
                                 value={selectedStatus}
                                 onChange={handleSelectStatusChange}
                                 displayEmpty
                                 label=""
                                 sx={{
                                 //   minHeight: 30,
                                   minWidth: 120,
                                   p: 1,
                                 }}
                               >
                                
                                <MenuItem onClick={()=>{setGenderFilter(false);setMainMenuOpen(false)}} value={false}>effacer le filtre</MenuItem>
                                 <MenuItem onClick={()=>{setStatusFilter(false);setMainMenuOpen(false)}} value={"Payé"}>Payé</MenuItem>
                                 <MenuItem onClick={()=>{setStatusFilter(false);setMainMenuOpen(false)}} value={"Non Payé"}>Non Payé</MenuItem>
                                
                               </Select>
                             </FormControl>
                          </div>   
                              }



      </Grid>

          </Grid>
          <br/>
          
        <Grid container spacing={2}>
            <Grid item xs={8} md={12} lg={12}>
              <div style={{background: '#F8F8F8',  padding: '10px'}}>
               {activeButton === 'viewStudents' &&  <ViewStudents students={studentsToDisplay}/>}  
               {/* <ViewStudents /> */}
               {activeButton === 'addStudents' && <AddStudent />}
                </div>
            </Grid>
            
          </Grid>
      </Container>
    </>
  );
}
