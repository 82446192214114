import { db, fb, auth, storage } from '../../config/firebase';
import { clearUser, loginFailed, loginSuccess, logoutFxn, saveSchool, signupFailed, storeUserData } from '../reducers/auth.slice';
import { v4 as uuidv4 } from 'uuid';
import { notifyErrorFxn, notifySuccessFxn } from 'src/utils/toast-fxn';
import { clearGroup } from '../reducers/group.slice';
import { saveAPEAE, saveAdmissionFee, saveExamsPerTerm, saveHolidayEnd, saveHolidayStart, saveInstallment, saveOrangeMerchantApi, savePasswordTeacher, saveReadmissionFee, saveResumptionDate, saveThemeColor, saveThemeImage, saveTuitionFee } from '../reducers/settings.slice';

import firebase from "firebase/app";

export const signin = (user, navigate, setLoading) => async (dispatch) => {
  fb.auth().signInWithEmailAndPassword(user.email, user.password)
  .then((userCredential) => {
    // Signed in
    var user = userCredential.user;
    console.log('Signed In user is: ', user.email);
     dispatch(fetchUserData(user.uid, "sigin", navigate, setLoading));
  })
  .catch((error) => {
    setLoading(false);
    var errorCode = error.code;
    var errorMessage = error.message;
    notifyErrorFxn(errorMessage);
    console.log('Error Code is: ', errorCode, + ' Msg is: ', errorMessage);
    dispatch(loginFailed(errorMessage));
  });

};


export const signup = (user, navigate, setLoading) => async (dispatch) => {
var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
var today  = new Date();

  fb.auth().createUserWithEmailAndPassword(
    user.email,
    user.password
).then((res)=>{
  console.log("Good to go...");
  return db.collection('schools').doc(res.user.uid).set({
    schoolId: res.user.uid,
    email: user.email,
    schoolName: user.sname,
    firstName: user.fname,
    lastName: user.lname,
    phone: user.phone,
    password: user.password,
    accountCreated: today.toLocaleDateString("en-US", options),
    settings:{
      themeColor:"#D72A34",
      themeImage:"https://firebasestorage.googleapis.com/v0/b/boncole-44e56.appspot.com/o/theme_images%2Fe2b4eaae-520b-41b1-b463-f6369aa92604.png?alt=media&token=8ed9de8a-4777-4349-b30f-8344b8029ba7"
    },

   resumptionDate:"1999-01-01",
   holidayEnd:"1999-01-01",
   holidayStart:"1999-01-01",
   apeae:0,
   installments:1,
   levels:[],
   
   teacherPassword:"123456",
   announcementsList:[],
   orangeMerchantApi:"",
   readmissionFee:"",
   onlinePayment:" ",
   admissionFee:" ",
   tuitionDeadline:""

  })
}).then(() => {
  notifySuccessFxn('Registered Successfully✔');
  navigate('/login', { replace: true });
}).catch((err) => {
  console.error("Error signing up: ", err);
  var errorMessage = err.message;
  notifyErrorFxn(errorMessage);
  dispatch(signupFailed({ errorMessage }));
  setLoading(false);
})
}


export const uploadImage = (user, file, navigate, setLoading) => async (dispatch) => {
  const imageName = uuidv4() + '.' + file?.name?.split('.')?.pop();
  console.log('File Name: ', imageName);
  const uploadTask = storage.ref(`profile_images/${imageName}`).put(file);
  uploadTask.on(
    "state_changed",
    snapshot => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      // setProgress(progress);
    },
    error => {
      console.log(error);
    },
    () => {
      storage
        .ref("profile_images")
        .child(imageName)
        .getDownloadURL()
        .then(url => {
          console.log('Image URL: ', url);
          dispatch(signup(user, file, navigate, setLoading, url));
        });
    }
  );
}


export const fetchUserData = (id, type, navigate, setLoading) => async (dispatch) => {
  var user = db.collection("schools").doc(id);
  user.get().then((doc) => {
  if (doc.exists) {
    // console.log("User Data:", doc.data());

 db.collection('schools').doc(doc.data().schoolId).get()
 .then((doc2)=>{

  dispatch(saveSchool(doc2.data()))
  /**==================  ADDITIONAL DATA THAT MAY NOT BE THERE ON INITIAL  SIGNUP ============================= */
  dispatch(saveThemeColor(doc2.data().settings && doc2.data().settings.themeColor?doc2.data().settings.themeColor :null ))

  dispatch(saveThemeImage(doc2.data().settings && doc2.data().settings.themeImage?doc2.data().settings.themeImage:null ))

  dispatch(saveResumptionDate(doc2.data().resumptionDate?doc2.data().resumptionDate :'1999-01-01'  ))
  dispatch(saveHolidayStart(doc2.data().holidayStart?doc2.data().holidayStart:'1999-01-01'   ))
  dispatch(saveHolidayEnd(doc2.data().holidayEnd?doc2.data().holidayEnd:'1999-01-01'  ))

  dispatch(saveInstallment(doc2.data().installments?doc2.data().installments:0))
  dispatch(saveInstallment(doc2.data().installments?doc2.data().installments:0))
  dispatch(saveAPEAE(doc2.data().apeae? doc2.data().apeae:0  ))

  dispatch(saveAdmissionFee(doc2.data().admissionFee?doc2.data().admissionFee:"0"))
  dispatch(saveTuitionFee(doc2.data().tuitionFee?doc2.data().tuitionFee:"0"))
  dispatch(saveReadmissionFee(doc2.data().readmissionFee?doc2.data().readmissionFee:"0"))
  dispatch(saveOrangeMerchantApi(doc2.data().orangeMerchantApi?doc2.data().orangeMerchantApi:"none"))
  dispatch(savePasswordTeacher(doc2.data().teacherPassword?doc2.data().teacherPassword:"0"))
  dispatch(saveExamsPerTerm(doc2.data().examsPerTerm?doc2.data().examsPerTerm:1))

/**=============================================== */
  dispatch(storeUserData(doc.data()));
  if(type === "sigin"){
    notifySuccessFxn("Logged In😊");
    navigate('/dashboard/home', { replace: true });
  }

 }).catch((error) => {
  console.log("Error getting document:", error);
  notifyErrorFxn("School not found");

});

  
  }
   else {
      setLoading(false);
      notifyErrorFxn("Unauthorized❌")
      console.log("No such document!");
  }
}).catch((error) => {
  console.log("Error getting document:", error);
});
return user;
};


export const uploadProfileImage = (profileData, file, userID, navigate, setLoading) => async (dispatch) => {
  const imageName = uuidv4() + '.' + file?.name?.split('.')?.pop();
  console.log('File Name: ', imageName);
  const uploadTask = storage.ref(`profile_images/${imageName}`).put(file);
  uploadTask.on(
    "state_changed",
    snapshot => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      // setProgress(progress);
    },
    error => {
      console.log(error);
    },
    () => {
      storage
        .ref("profile_images")
        .child(imageName)
        .getDownloadURL()
        .then(url => {
          console.log('Image URL: ', url);
          dispatch(updateProfile(profileData, userID, file, navigate, setLoading, url));
        });
    }
  );
}

export const updateProfile = (profileData, userID, file, navigate, setLoading, url) => async (dispatch) => {
  // return  
  db.collection('employees').doc(userID).update({
    paymentLink: profileData.paymentLink,
    imageUrl: url,
  }).then((res)=>{
       if(profileData?.password){
        //update password start
        const user = auth.currentUser;
        user.updatePassword(profileData.password)
          .then(() => {
            setLoading(false);
            console.log("Password updated successfully");
            notifySuccessFxn("Updated successfully");
            navigate('/dashboard/home', { replace: true });
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error updating password: ", error);
            notifyErrorFxn(error.message);
          });
       //update password end
       }else{
        setLoading(false);
        console.error("No Password to update");
        notifySuccessFxn("Updated successfully");
        navigate('/dashboard/home', { replace: true });
       }
     
  }).catch((err) => {
    setLoading(false);
    console.log("ERR-: ", err);
  })
}



//UPLOADING  IMAGE FROM THE  SETTINGS PAGE (THE FUNCTION BELOW)
export const uploadProfileSettings = (userPreferences, file,schoolID) => async (dispatch) => {
  const imageName = uuidv4() + '.' + file?.name?.split('.')?.pop();
  console.log('File Name-->: ', imageName);
  const uploadTask = storage.ref(`theme_images/${imageName}`).put(file);
  uploadTask.on(
    "state_changed",
    snapshot => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      // setProgress(progress);
    },
    error => {
      console.log(error);
    },
    () => {
      storage
        .ref("theme_images")
        .child(imageName)
        .getDownloadURL()
        .then(url => {
          console.log('Image URL: ', url);
          dispatch(updateUserSettings(userPreferences, schoolID, url));
        });
    }
  );
}




export const updateUserSettings = (userPreferences, schoolID,url) => async (dispatch) => {
  // return  

  db.collection('schools').doc(schoolID).get().then((dac)=>{

  if(url){
    userPreferences = {...userPreferences,themeImageUnsaved:url}
  }

  console.log("STORED URL-->",url)


if(!userPreferences.themeImageUnsaved ||userPreferences.themeImageUnsaved[0] !== '#' )
{

  db.collection('events').add({
   
    eventStart:userPreferences.holidayStart,
    eventEnd:userPreferences.holidayEnd,
    eventName:userPreferences.resumptionDate,
    schoolId:schoolID,
    
  }).then((res)=>{

    return db.collection('events').doc(res.id).update({
      eventId: res.id,
      id:res.id
    })


  }).catch((error) => {
    //setLoading(false);
    console.error("couldn't add new event: ", error);
    notifyErrorFxn(error.message);
  });



  console.log("USER PREFERENCES BEING SENT IN IS-->",userPreferences)

  db.collection('schools').doc(schoolID).update({
    settings:{themeColor:userPreferences.themeColorUnsaved,
              themeImage: dac.data().settings.themeImage
            },
            apeae:userPreferences.apeae,
            installments:userPreferences.installment,
            tuitionFee:userPreferences.tuitionFee,
            admissionFee:userPreferences.admissionFee,
            readmissionFee:userPreferences.readmissionFee,
            onlinePayment:userPreferences.onlinePayment,
            teacherPassowrd:userPreferences.passwordTeacher,
            orangeMerchantApi:userPreferences.orangeMerchantApi,
            
     /* holidayStart:userPreferences.holidayStart,
      holidayEnd:userPreferences.holidayEnd,
      resumptionDate:userPreferences.resumptionDate,
      tuitionDeadline:userPreferences.resumptionDate,*/

         levels:firebase.firestore.FieldValue.arrayUnion(
         {
         tuitionFeeAtLevel:userPreferences.tuitionFeeAtLevel,
         subjectsForLevel:userPreferences.subjectsForLevel,
         termSecondary: userPreferences.termSecondary,
         termElementary:userPreferences.termElementary,
         levelName: userPreferences.selectedLevelToAddToSchool,
         examsPerTerm:userPreferences.examsPerTerm,
      }
      )

    }




    
   ).then((res)=>{
        if(userPreferences?.password){
         //update password start
         const user = auth.currentUser;
         user.updatePassword(userPreferences.password)
           .then(() => {
             //setLoading(false);
             console.log("our first update to the database went swimmingly");
            // notifySuccessFxn("Updated successfully");
            // navigate('/dashboard/home', { replace: true });
           })
           .catch((error) => {
             //setLoading(false);
             console.error("Error updating ,first update to db: ", error);
             notifyErrorFxn(error.message);
           });
        //update password end
        }else{
         //setLoading(false);
         console.error("No Password to update");
        // notifySuccessFxn("Updated successfully");
         //navigate('/dashboard/home', { replace: true });
        }
      
   }).catch((err) => {
     //setLoading(false);
     console.log("ERR-: ", err);
   })

 
}


  else{


    db.collection('events').add({
   
      eventStart:userPreferences.holidayStart,
      eventEnd:userPreferences.holidayEnd,
      eventName:userPreferences.resumptionDate,
      schoolId:schoolID,
      
    }).then((res)=>{
  
      return db.collection('events').doc(res.id).update({
        eventId: res.id,
        id:res.id
      })
  
  
    }).catch((error) => {
      //setLoading(false);
      console.error("couldn't add new event successfully: ", error);
      notifyErrorFxn(error.message);
    });
  




 console.log("USER PREFERENCES BEING SENT IN IS-->",userPreferences)

  db.collection('schools').doc(schoolID).update({
    settings:{themeColor:userPreferences.themeColorUnsaved,
      themeImage: userPreferences.themeImageUnsaved,
    },
    apeae:userPreferences.apeae,
    installments:userPreferences.installment,
    tuitionFee:userPreferences.tuitionFee,
    admissionFee:userPreferences.admissionFee,
    readmissionFee:userPreferences.readmissionFee,
    onlinePayment:userPreferences.onlinePayment,
    teacherPassowrd:userPreferences.teacherPassowrd,
    orangeMerchantApi:userPreferences.orangeMerchantApi,
    
    /*holidayStart:userPreferences.holidayStart,
    holidayEnd:userPreferences.holidayEnd,
    resumptionDate:userPreferences.resumptionDate,
    tuitionDeadline:userPreferences.resumptionDate,*/
    levels:firebase.firestore.FieldValue.arrayUnion(
      {
      tuitionFeeAtLevel:userPreferences.tuitionFeeAtLevel,
      subjectsForLevel:userPreferences.subjectsForLevel,
      termSecondary: userPreferences.termSecondary,
      termElementary:userPreferences.termElementary,
      levelName: userPreferences.selectedLevelToAddToSchool,
      examsPerTerm:userPreferences.examsPerTerm
   }
   )
   
  }).then((res)=>{
       if(userPreferences?.password){
        //update password start
        const user = auth.currentUser;
        user.updatePassword(userPreferences.password)
          .then(() => {
            //setLoading(false);
            console.log("our first update to the database went swimmingly");
           
           // navigate('/dashboard/home', { replace: true });
          })
          .catch((error) => {
            //setLoading(false);
            console.error("Error updating ,first update to db: ", error);
            notifyErrorFxn(error.message);
          });
       //update password end
       }else{
        //setLoading(false);
        console.error("No Password to update");
        //notifySuccessFxn("Updated successfully");
        //navigate('/dashboard/home', { replace: true });
       }
     
  }).catch((err) => {
    //setLoading(false);
    console.log("ERR-: ", err);
  })

}


  }).then(()=>{ //END OF INITIAL GET OF ADMIN DATA


  db.collection('schools').doc(schoolID).get().then((duc)=>{

    console.log("theme image after update is-->",duc.data().settings.themeImage)
    console.log("theme color after update is-->",duc.data().settings.themeColor)


    dispatch(saveThemeImage(duc.data().settings.themeImage))
    dispatch(saveThemeColor(duc.data().settings.themeColor))
    dispatch(saveAPEAE(duc.data().apeae))
    dispatch(saveInstallment(duc.data().installments))

  }).then(()=>{
    
    notifySuccessFxn("Updated successfully");
  })

})


}







export const logout = (navigate) => async (dispatch) => {
  fb.auth().signOut().then(() => {
    dispatch(logoutFxn());
    dispatch(clearUser());
    dispatch(clearGroup());
    navigate('/login', { replace: true });
    notifySuccessFxn("Logged out!")
    console.log('logout successful!');
  }).catch((error) => {
    // An error happened.
    console.log('logout failed response: ', error.message);
  });
  
}